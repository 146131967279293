<template>
  <div id="onetrust-consent-sdk" v-show="showCookiesBanner">
    <div
        class="onetrust-pc-dark-filter ot-fade-in"
        style="z-index: 2147483645"
    ></div>
    <div
        id="onetrust-pc-sdk"
        class="otPcTab ot-hide ot-fade-in"
        role="dialog"
        aria-modal="true"
        aria-labelledby="ot-pc-title"
        lang="uk"
    >
      <!-- pc header -->
      <div class="ot-pc-header" role="presentation">
        <!-- Header logo -->
        <div
            class="ot-pc-logo"
            role="img"
            aria-label="Логотип компанії"
            style="
            background-image: url('https://cdn.cookielaw.org/logos/d6256650-eff4-4a9a-a19a-9b0cb572a55f/4dce2d6b-7b9e-41bf-99c3-55ef48e05718/51161428-63f2-48af-a9db-4fd28f19a192/Logo_TevaPharm.png');
            background-position: left;
          "
        ></div>
        <div class="ot-title-cntr">
          <h2 id="ot-pc-title">Центр настройки конфіденційності</h2>
          <div class="ot-close-cntr">
            <button
                id="close-pc-btn-handler"
                @click="hideSetting()"
                class="ot-close-icon"
                aria-label="Закрити"
            ></button>
          </div>
        </div>
      </div>
      <!-- content --><!-- Groups / Sub groups with cookies -->
      <div id="ot-pc-content" class="ot-pc-scrollbar ot-sdk-row">
        <div class="ot-sdk-container ot-grps-cntr ot-sdk-column">
          <div
              class="ot-sdk-four ot-sdk-columns ot-tab-list"
              aria-label="Cookie Categories"
          >
            <ul class="ot-cat-grp" role="tablist">
              <li
                  class="ot-cat-item"
                  role="presentation"
                  data-optanongroupid="C0000"
                  @click="tabChange"
              >
                <!-- About Privacy container -->
                <div
                    class="ot-active-menu category-menu-switch-handler"
                    role="tab"
                    tabindex="0"
                    aria-selected="true"
                    aria-controls="ot-tab-desc"
                >
                  <h3 id="ot-pvcy-txt">Ваша конфіденційність</h3>
                </div>
              </li>
              <li
                  class="ot-cat-item ot-always-active-group"
                  role="presentation"
                  @click="tabChange"
                  data-optanongroupid="C0001"
              >
                <div
                    class="category-menu-switch-handler"
                    role="tab"
                    tabindex="-1"
                    aria-selected="false"
                    aria-controls="ot-desc-id-C0001"
                >
                  <h3 id="ot-header-id-C0001">Строго необхідні cookie</h3>
                </div>
              </li>
              <li
                  class="ot-cat-item"
                  role="presentation"
                  @click="tabChange"
                  data-optanongroupid="C0004"
              >
                <div
                    class="category-menu-switch-handler"
                    role="tab"
                    tabindex="-1"
                    aria-selected="false"
                    aria-controls="ot-desc-id-C0004"
                >
                  <h3 id="ot-header-id-C0004">Цільові сookies</h3>
                </div>
              </li>
              <li
                  class="ot-cat-item"
                  @click="tabChange"
                  role="presentation"
                  data-optanongroupid="C0003"
              >
                <div
                    class="category-menu-switch-handler"
                    role="tab"
                    tabindex="-1"
                    aria-selected="false"
                    aria-controls="ot-desc-id-C0003"
                >
                  <h3 id="ot-header-id-C0003">Функціональні cookies</h3>
                </div>
              </li>
              <li
                  class="ot-cat-item"
                  @click="tabChange"
                  role="presentation"
                  data-optanongroupid="C0002"
              >
                <div
                    class="category-menu-switch-handler"
                    role="tab"
                    tabindex="-1"
                    aria-selected="false"
                    aria-controls="ot-desc-id-C0002"
                >
                  <h3 id="ot-header-id-C0002">Експлуатаційні cookie</h3>
                </div>
              </li>
            </ul>
          </div>
          <div class="ot-tab-desc ot-sdk-eight ot-sdk-columns">
            <div
                class="ot-desc-cntr"
                id="ot-desc-id-C0000"
                tabindex="0"
                role="tabpanel"
                aria-labelledby="ot-pvcy-hdr"
            >
              <h3 id="ot-pvcy-hdr">Ваша конфіденційність</h3>
              <p id="ot-pc-desc" class="ot-grp-desc">
                Коли ви відвідуєте будь-який веб-сайт, він може зберігати або
                отримувати інформацію з вашого браузера, в основному у формі
                файлів cookie. Ця інформація може стосуватися вас, ваших
                уподобань, вашого пристрою або використовуватися для того, щоб
                сайт працював так, як ви очікуєте. Інформація зазвичай не
                ідентифікує вас безпосередньо, але може дати вам більш
                персоналізований веб-досвід. Ви можете не дозволяти використання
                деяких типів файлів cookie. Натисніть на заголовки різних
                категорій, щоб дізнатися більше і змінити настройки за
                замовчуванням. Тим не менш, ви повинні знати, що блокування
                деяких типів файлів cookie може вплинути на вашу роботу з сайтом
                і послугами, які ми можемо запропонувати. <br/><a
                  href="https://www.tevapharm.com/our-company/corporate-governance/data-privacy/"
                  class="privacy-notice-link"
                  rel="noopener"
                  target="_blank"
                  aria-label="Додаткові відомості про конфіденційність, відкривається в новому вікні"
              >Більше інформації</a
              >
              </p>
            </div>
            <div
                class="ot-desc-cntr ot-hide ot-always-active-group"
                role="tabpanel"
                tabindex="0"
                id="ot-desc-id-C0001"
            >
              <div class="ot-grp-hdr1">
                <h3 class="ot-cat-header">Строго необхідні cookie</h3>
                <div class="ot-tgl-cntr">
                  <div class="ot-always-active">Завжди активний</div>
                </div>
              </div>
              <p class="ot-grp-desc ot-category-desc">
                Ці файли cookie необхідні для функціонування веб-сайту і не
                можуть бути відключені в наших системах. Зазвичай вони
                встановлюються тільки у відповідь на ваші дії, що призводять до
                запиту на послуги, такі як установка налаштувань
                конфіденційності, вхід в систему або заповнення форм. Ви можете
                налаштувати свій браузер так, щоб він блокував або попереджав
                вас про ці файли cookie, але деякі частини сайту можуть не
                працювати.
              </p>
            </div>
            <div
                class="ot-desc-cntr ot-hide"
                role="tabpanel"
                tabindex="0"
                id="ot-desc-id-C0004"
            >
              <div class="ot-grp-hdr1">
                <h3 class="ot-cat-header">Цільові сookies</h3>
                <div class="ot-tgl">
                  <input
                      type="checkbox"
                      name="ot-group-id-C0004"
                      id="ot-group-id-C0004"
                      aria-checked="false"
                      role="switch"
                      class="category-switch-handler"
                      data-optanongroupid="C0004"
                      aria-labelledby="ot-header-id-C0004"
                      v-model="targetCookies"
                  />
                  <label class="ot-switch" for="ot-group-id-C0004"
                  ><span class="ot-switch-nob"></span>
                    <span class="ot-label-txt">Цільові сookies</span></label
                  >
                </div>
                <div class="ot-tgl-cntr"></div>
              </div>
              <p class="ot-grp-desc ot-category-desc">
                Ці файли cookie встановлюються через наш сайт нашими рекламними
                партнерами. Вони можуть використовуватися цими компаніями для
                створення профілю ваших інтересів і показу вам релевантних
                оголошень на інших сайтах. Вони працюють, унікально
                ідентифікуючи ваш браузер і пристрій. Якщо ви не дозволите
                використання цих файлів cookie, ви не побачите нашу цільову
                рекламу на різних сайтах.
              </p>
            </div>
            <div
                class="ot-desc-cntr ot-hide"
                role="tabpanel"
                tabindex="0"
                id="ot-desc-id-C0003"
            >
              <div class="ot-grp-hdr1">
                <h3 class="ot-cat-header">Функціональні cookies</h3>
                <div class="ot-tgl">
                  <input
                      type="checkbox"
                      name="ot-group-id-C0003"
                      id="ot-group-id-C0003"
                      aria-checked="false"
                      role="switch"
                      class="category-switch-handler"
                      data-optanongroupid="C0003"
                      aria-labelledby="ot-header-id-C0003"
                      v-model="functionalCookies"
                  />
                  <label class="ot-switch" for="ot-group-id-C0003"
                  ><span class="ot-switch-nob"></span>
                    <span class="ot-label-txt"
                    >Функціональні cookies</span
                    ></label
                  >
                </div>
                <div class="ot-tgl-cntr"></div>
              </div>
              <p class="ot-grp-desc ot-category-desc">
                Ці файли cookie надають розширені функціональні можливості і
                персоналізацію, такі як відео і чат. Вони можуть бути
                встановлені нами або сторонніми постачальниками, чиї послуги ми
                додали на наші сторінки. Якщо ви не дозволите використання цих
                файлів cookie, деякі або всі ці функції можуть працювати
                неправильно.
              </p>
            </div>
            <div
                class="ot-desc-cntr ot-hide"
                role="tabpanel"
                tabindex="0"
                id="ot-desc-id-C0002"
            >
              <div class="ot-grp-hdr1">
                <h3 class="ot-cat-header">Експлуатаційні cookie</h3>
                <div class="ot-tgl">
                  <input
                      type="checkbox"
                      name="ot-group-id-C0002"
                      id="ot-group-id-C0002"
                      aria-checked="false"
                      role="switch"
                      class="category-switch-handler"
                      data-optanongroupid="C0002"
                      aria-labelledby="ot-header-id-C0002"
                      v-model="operationalCookies"
                  />
                  <label class="ot-switch" for="ot-group-id-C0002"
                  ><span class="ot-switch-nob"></span>
                    <span class="ot-label-txt"
                    >Експлуатаційні cookie</span
                    ></label
                  >
                </div>
                <div class="ot-tgl-cntr"></div>
              </div>
              <p class="ot-grp-desc ot-category-desc">
                Ці файли cookie дозволяють нам розраховувати відвідування і
                джерела трафіку, щоб ми могли вимірювати і покращувати
                продуктивність нашого сайту. Вони допомагають нам дізнатися, які
                сторінки є найбільш і найменш популярними, та побачити, як
                відвідувачі переміщуються по сайту. Вся інформація, яку збирають
                файли cookies, консолідується і не може використовуватись для
                прямої ідентифікації. Якщо ви не дозволите використання цих
                файлів cookie, ми не дізнаємося, коли ви відвідали наш сайт.
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- Vendors / Hosts -->
      <section id="ot-pc-lst" class="ot-hide ot-enbl-chr">
        <div class="ot-lst-cntr ot-pc-scrollbar">
          <div id="ot-pc-hdr">
            <h3 id="ot-lst-title">
              <button class="ot-link-btn back-btn-handler" aria-label="Back">
                <svg
                    id="ot-back-arw"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 444.531 444.531"
                    xml:space="preserve"
                >
                  <title>Back Button</title>
                  <g>
                    <path
                        fill="#656565"
                        d="M213.13,222.409L351.88,83.653c7.05-7.043,10.567-15.657,10.567-25.841c0-10.183-3.518-18.793-10.567-25.835
                  l-21.409-21.416C323.432,3.521,314.817,0,304.637,0s-18.791,3.521-25.841,10.561L92.649,196.425
                  c-7.044,7.043-10.566,15.656-10.566,25.841s3.521,18.791,10.566,25.837l186.146,185.864c7.05,7.043,15.66,10.564,25.841,10.564
                  s18.795-3.521,25.834-10.564l21.409-21.412c7.05-7.039,10.567-15.604,10.567-25.697c0-10.085-3.518-18.746-10.567-25.978
                  L213.13,222.409z"
                    ></path>
                  </g>
                </svg>
              </button>
              <span>Back</span>
            </h3>
            <div class="ot-lst-subhdr">
              <div id="ot-search-cntr">
                <p role="status" class="ot-scrn-rdr"></p>
                <label for="vendor-search-handler" class="ot-scrn-rdr"
                >Vendor Search</label
                >
                <input
                    id="vendor-search-handler"
                    aria-label="Vendor Search"
                    type="text"
                    placeholder="Search..."
                    name="vendor-search-handler"
                />
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 -30 110 110"
                >
                  <path
                      fill="#2e3644"
                      d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23
              s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92
              c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17
              s-17-7.626-17-17S14.61,6,23.984,6z"
                  ></path>
                </svg>
              </div>
              <div id="ot-fltr-cntr">
                <button
                    id="filter-btn-handler"
                    aria-label="Filter"
                    aria-haspopup="true"
                >
                  <svg
                      role="presentation"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 402.577 402.577"
                      style="enable-background: new 0 0 402.577 402.577"
                      xml:space="preserve"
                  >
                    <title>Filter Button</title>
                    <g>
                      <path
                          fill="#2c3643"
                          d="M400.858,11.427c-3.241-7.421-8.85-11.132-16.854-11.136H18.564c-7.993,0-13.61,3.715-16.846,11.136
                            c-3.234,7.801-1.903,14.467,3.999,19.985l140.757,140.753v138.755c0,4.955,1.809,9.232,5.424,12.854l73.085,73.083
                            c3.429,3.614,7.71,5.428,12.851,5.428c2.282,0,4.66-0.479,7.135-1.43c7.426-3.238,11.14-8.851,11.14-16.845V172.166L396.861,31.413
                            C402.765,25.895,404.093,19.231,400.858,11.427z"
                      ></path>
                    </g>
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <section id="ot-lst-cnt" class="ot-pc-scrollbar">
            <div class="ot-sdk-row">
              <div class="ot-sdk-column">
                <div id="ot-sel-blk">
                  <div class="ot-sel-all">
                    <div class="ot-sel-all-hdr">
                      <span class="ot-consent-hdr">Consent</span>
                      <span class="ot-li-hdr">Leg.Interest</span>
                    </div>
                    <div class="ot-sel-all-chkbox">
                      <div class="ot-chkbox" id="ot-selall-hostcntr">
                        <input
                            id="select-all-hosts-groups-handler"
                            type="checkbox"
                            aria-checked="false"
                        />
                        <label for="select-all-hosts-groups-handler"
                        ><span class="ot-label-txt"
                        >checkbox label</span
                        ></label
                        >
                        <span class="ot-label-status">label</span>
                      </div>
                      <div class="ot-chkbox" id="ot-selall-vencntr">
                        <input
                            id="select-all-vendor-groups-handler"
                            type="checkbox"
                            aria-checked="false"
                        />
                        <label for="select-all-vendor-groups-handler"
                        ><span class="ot-label-txt"
                        >checkbox label</span
                        ></label
                        >
                        <span class="ot-label-status">label</span>
                      </div>
                      <div class="ot-chkbox" id="ot-selall-licntr">
                        <input
                            id="select-all-vendor-leg-handler"
                            type="checkbox"
                            aria-checked="false"
                        />
                        <label for="select-all-vendor-leg-handler"
                        ><span class="ot-label-txt"
                        >checkbox label</span
                        ></label
                        >
                        <span class="ot-label-status">label</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div id="ot-anchor"></div>
        <section id="ot-fltr-modal">
          <div id="ot-fltr-cnt">
            <button id="clear-filters-handler">Clear</button>
            <div class="ot-fltr-scrlcnt ot-pc-scrollbar">
              <div class="ot-fltr-opts">
                <div class="ot-fltr-opt">
                  <div class="ot-chkbox">
                    <input
                        id="chkbox-id"
                        type="checkbox"
                        aria-checked="false"
                        class="category-filter-handler"
                    />
                    <label for="chkbox-id"
                    ><span class="ot-label-txt">checkbox label</span></label
                    >
                    <span class="ot-label-status">label</span>
                  </div>
                </div>
              </div>
              <div class="ot-fltr-btns">
                <button id="filter-apply-handler">Apply</button>
                <button id="filter-cancel-handler">Cancel</button>
              </div>
            </div>
          </div>
        </section>
      </section>
      <!-- Footer buttons and logo -->
      <div class="ot-pc-footer">
        <div class="ot-btn-container">
          <button
              class="save-preference-btn-handler onetrust-close-btn-handler"
              @click="accept"
          >
            Підтвердити мої вибори
          </button>
          <div class="ot-btn-subcntr">
            <button id="accept-recommended-btn-handler" @click="acceptAll">
              Дозволити все
            </button>
          </div>
        </div>
        <!-- <div class="ot-pc-footer-logo">
          <a
            href="https://www.onetrust.com/products/cookie-consent/"
            target="_blank"
            rel="noopener noreferrer"
            style="
              background-image: url('https://cdn.cookielaw.org/logos/static/poweredBy_ot_logo.svg');
            "
            aria-label="Powered by OneTrust Відкривається в новому вікні"
          ></a>
        </div> -->
      </div>
      <!-- Cookie subgroup container --><!-- Vendor list link --><!-- Cookie lost link --><!-- Toggle HTML element -->
      <!-- Checkbox HTML --><!-- Arrow SVG element --><!-- Accordion basic element --><span
        class="ot-scrn-rdr"
        aria-atomic="true"
        aria-live="polite"
    ></span
    >
      <iframe
          class="ot-text-resize"
          title="onetrust-text-resize"
          style="position: absolute; top: -50000px; width: 100em"
          aria-hidden="true"
      ></iframe>
    </div>
    <div
        id="onetrust-banner-sdk"
        class="otCenterRounded default ot-wo-title vertical-align-content"
        tabindex="0"
    >
      <div
          class="ot-sdk-container"
          role="dialog"
          aria-describedby="onetrust-policy-text"
      >
        <div class="ot-sdk-row">
          <div
              id="onetrust-group-container"
              class="ot-sdk-twelve ot-sdk-columns"
          >
            <div id="onetrust-policy">
              <div class="banner-header">
                <div class="banner_logo"></div>
              </div>
              <p id="onetrust-policy-text">
                Ми б хотіли збирати дані, щоб покращувати досвід перебування на
                сайті, аналізувати і вимірювати залученість у контент. Натискаючи
                Погоджуюсь, ви даєте згоду на це та передачу даних нашим
                партнерам, котрі можуть використовувати їх для обрання
                релевантних рекламних повідомлень на інших сайтах. Якщо ви
                продовжуєте перегляд без згоди на доступ до персональних даних,
                це може вплинути на роботу сайту і досвід його використання. Ви
                можете змінити думку у будь-який час. Більше інформації про
                права, наш підхід та дані, які збираємо ми та наші партнери, ви
                знайдете<a target="_blank"
                           href="https://www.tevapharm.com/our-company/corporate-governance/data-privacy/"
              >тут.</a
              >
              </p>
            </div>
          </div>
          <div
              id="onetrust-button-group-parent"
              class="ot-sdk-twelve ot-sdk-columns has-reject-all-button"
          >
            <div id="onetrust-button-group">
              <div class="banner-actions-container">

                <button id="onetrust-accept-btn-handler" @click="acceptAll">
                  Прийняти всі файли сookie
                </button>
                <button id="onetrust-reject-all-handler" @click="rejectAll">
                  Відхилити все
                </button>
              </div>
              <button
                  id="onetrust-pc-btn-handler"
                  @click="settingCookies"
                  class="cookie-setting-link"
              >
                Налаштування cookie
              </button>
            </div>
          </div>
          <!-- Close Button -->
          <div id="onetrust-close-btn-container"></div>
          <!-- Close Button END-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showCookiesBanner: true,
      targetCookies: false,
      functionalCookies: false,
      operationalCookies: false,
    };
  },
  methods: {
    // start banner nav
    settingCookies() {
      document.querySelector("#onetrust-pc-sdk").classList.remove("ot-hide");
      document.querySelector('.onetrust-pc-dark-filter').style.removeProperty('z-index');
    },
    hideSetting() {
      document.querySelector("#onetrust-pc-sdk").classList.add("ot-hide");
      document.querySelector('.onetrust-pc-dark-filter').style.zIndex = "100"
    },
    hideBanner() {
      this.hideSetting();
      this.showCookiesBanner = false;

      localStorage.setItem('cookies', this.showCookiesBanner);

    },
    tabChange(event) {

      document
          .querySelectorAll(".category-menu-switch-handler")
          .forEach((el) => {
            if (el.classList.contains("ot-active-menu")) {
              el.classList.remove("ot-active-menu");
            }
          });

      document.querySelectorAll(".ot-desc-cntr").forEach((el) => {

        if (!el.classList.contains("ot-hide")) {
          el.classList.add("ot-hide");
        }
      });


      var path = event.path || (event.composedPath && event.composedPath());
      path.forEach((el) => {
        if (el.classList) {
          if (el.classList.contains("ot-cat-item")) {
            document
                .querySelector(
                    `#ot-desc-id-${el.getAttribute("data-optanongroupid")}`
                )
                .classList.remove("ot-hide");

            el.querySelector(".category-menu-switch-handler").classList.add(
                "ot-active-menu"
            );
          }
        }
      });


    },
    // end banner nav
    setCookie(name, value, options = {}) {
      options = {
        path: "/",
        // при необходимости добавьте другие значения по умолчанию
        ...options,
      };

      if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
      }

      let updatedCookie =
          encodeURIComponent(name) + "=" + encodeURIComponent(value);

      for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
          updatedCookie += "=" + optionValue;
        }
      }

      document.cookie = updatedCookie;
    },
    getCookie(name) {
      let matches = document.cookie.match(
          new RegExp(
              "(?:^|; )" +
              name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
              "=([^;]*)"
          )
      );
      return matches ? decodeURIComponent(matches[1]) : undefined;
    },
    deleteCookie(name) {
      this.setCookie(name, "", {
        "max-age": -1,
      });
    },
    cookiesDelete() {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;";
        document.cookie = name + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      }
    },

    acceptAll() {
      this.hideSetting();

      this.targetCookies = true;
      this.functionalCookies = true;
      this.operationalCookies = true;

      this.hideBanner();
      this.acceptCookies();
      // setTimeout(() => {
      //   window.location.reload()
      // }, 1)

    },
    rejectAll() {
      this.hideSetting();

      this.targetCookies = false;
      this.functionalCookies = false;
      this.operationalCookies = false;

      this.hideBanner();
      this.acceptCookies();
      // setTimeout(() => {
      //   window.location.reload()
      // }, 1)


    },
    accept() {
      this.hideBanner();
      this.acceptCookies();

      // setTimeout(() => {
      //   window.location.reload()
      // }, 1)

    },
    acceptCookies() {
      this.cookiesDelete();


      let expires = new Date(new Date().valueOf() + 30000 * 60 * 60 * 24 * 15);
      document.cookie = "cbg-teva-targetCookies=" + this.targetCookies + "; expires=" + expires;
      document.cookie = "cbg-teva-functionalCookies=" + this.functionalCookies + "; expires=" + expires;
      document.cookie = "cbg-teva-operationalCookies=" + this.operationalCookies + "; expires=" + expires;

      if (!this.showCookiesBanner) {
        let expires = new Date(new Date().valueOf() + 30000 * 60 * 60 * 24 * 15);
        document.cookie = "cbg-teva-scb=false; expires=" + expires;
      }

      if (this.targetCookies) {

      }

      if (this.functionalCookies) {
        // -
        // console.log("functionalCookies");
      }

      if (this.operationalCookies) {
        // console.log("operationalCookies");
        // аналитике Гугла, Тег менеджера Гугла и Хотжар.


        // <!-- Global site tag (gtag.js) - Google Analytics -->
        // (function () {
        //   window.dataLayer = window.dataLayer || [];
        //
        //   function gtag() {
        //     dataLayer.push(arguments);
        //   }
        //
        //   gtag('js', new Date());
        //
        //   gtag('config', 'UA-134231168-1');
        // })();

        // (function () {
        //   window.dataLayer = window.dataLayer || [];
        //
        //   function gtag() {
        //     dataLayer.push(arguments);
        //   }
        //
        //   gtag('js', new Date());
        //
        //   gtag('config', 'UA-131211202-8');
        // })();
        //
        // (function (i, s, o, g, r, a, m) {
        //     i["esSdk"] = r;
        //     i[r] = i[r] || function () {
        //       (i[r].q = i[r].q || []).push(arguments)
        //     }, a = s.createElement(o), m = s.getElementsByTagName(o)[0];
        //     a.async = 1;
        //     a.src = g;
        //     m.parentNode.insertBefore(a, m)
        //   }
        // )(window, document, "script", "https://esputnik.com/scripts/v1/public/scripts?apiKey=eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI0NTI0ZWZhYTJkYzI2MGRmYTM4YTE1NDBlMWFhYjE0N2Q1ZmUyMDFiMmI0ZmNmOTgxY2FiNDk3MWQ3YTM1ODA4MzBhMGJkMmQ0ZGY1ZTE2MjI3MGU2OGY5YzIyMWRiMGEyZWUyYTMwOTUyMzc3NmJhYjUyYzUyZDQyMzA4ZmQzOWY1ZDhhNTM1M2U3NzY2NTNiOTczZjQ3ZmVmZDQ1MTFhMDY4ZGU0OTI5YzE4NjY1ZWZiMGJhMDk3OTZlNCJ9.XetfnnhfyDF7NmyjaQd9WnrgpojWR8tTGz8IkkOcmcde5gYy85aVuB-ikNbb5p9LokAjLm-S2qdG7mG1qUyFyg&domain=F0197177-FB78-4248-90DF-DB2756CEBB8F", "es");
        // es("pushOn");

        // (function (w, d, s, l, i) {
        //   w[l] = w[l] || [];
        //   w[l].push({
        //     'gtm.start':
        //       new Date().getTime(), event: 'gtm.js'
        //   });
        //   var f = d.getElementsByTagName(s)[0],
        //     j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
        //   j.async = true;
        //   j.src =
        //     'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        //   f.parentNode.insertBefore(j, f);
        // })(window, document, 'script', 'dataLayer', 'GTM-5KHW4WH');

        // (function (h, o, t, j, a, r) {
        //   h.hj = h.hj || function () {
        //     (h.hj.q = h.hj.q || []).push(arguments)
        //   };
        //   h._hjSettings = {hjid: 1193667, hjsv: 6};
        //   a = o.getElementsByTagName('head')[0];
        //   r = o.createElement('script');
        //   r.async = 1;
        //   r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        //   a.appendChild(r);
        // })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

        // (function (w, d, s, l, i) {
        //   w[l] = w[l] || [];
        //   w[l].push({
        //     'gtm.start':
        //       new Date().getTime(), event: 'gtm.js'
        //   });
        //   var f = d.getElementsByTagName(s)[0],
        //     j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
        //   j.async = true;
        //   j.src =
        //     'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        //   f.parentNode.insertBefore(j, f);
        // })(window, document, 'script', 'dataLayer', 'GTM-5KHW4WH');


      } else {
        // delete window.dataLayer;
        // window.gtag = function(){
        //   // stub function
        // }
        const cyrb53 = function (str, seed = 0) {
          let h1 = 0xdeadbeef ^ seed,
              h2 = 0x41c6ce57 ^ seed;
          for (let i = 0, ch; i < str.length; i++) {
            ch = str.charCodeAt(i);
            h1 = Math.imul(h1 ^ ch, 2654435761);
            h2 = Math.imul(h2 ^ ch, 1597334677);
          }
          h1 = Math.imul(h1 ^ h1 >>> 16, 2246822507) ^ Math.imul(h2 ^ h2 >>> 13, 3266489909);
          h2 = Math.imul(h2 ^ h2 >>> 16, 2246822507) ^ Math.imul(h1 ^ h1 >>> 13, 3266489909);
          return 4294967296 * (2097151 & h2) + (h1 >>> 0);
        };

        let clientIP = fetch('https://api.ipify.org?format=json')
            .then(x => x.json())
            .then(({ip}) => {
              return ip;
            });
        ;
        let validityInterval = Math.round(new Date() / 1000 / 3600 / 24 / 4);
        let clientIDSource = clientIP + ";" + window.location.host + ";" + navigator.userAgent + ";" + navigator.language + ";" + validityInterval;
        let clientIDHashed = cyrb53(clientIDSource).toString(16);

        window.dataLayer = window.dataLayer || [];

        gtag('js', new Date());



      }
    }

  },
  mounted() {

    if (localStorage.getItem('cookies') !== null) {
      this.showCookiesBanner = false
    }
      if (!this.showCookiesBanner) {
      this.showCookiesBanner = this.getCookie("cbg-teva-scb") !== "false" ? true : false;

      this.targetCookies = this.getCookie('cbg-teva-targetCookies') === "true" ? true : false;
      this.functionalCookies = this.getCookie('cbg-teva-functionalCookies') === "true" ? true : false;
      this.operationalCookies = this.getCookie('cbg-teva-operationalCookies') === "true" ? true : false;

      this.acceptCookies();


    }

  },
};
</script>

<style scoped lang="scss" id="onetrust-style">
* {
  font-family: "Commissioner-Regular";
}

#onetrust-banner-sdk {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

#onetrust-banner-sdk .onetrust-vendors-list-handler {
  cursor: pointer;
  color: #1f96db;
  font-size: inherit;
  font-weight: bold;
  text-decoration: none;
  margin-left: 5px;
}

#onetrust-banner-sdk .onetrust-vendors-list-handler:hover {
  color: #1f96db;
}

#onetrust-banner-sdk:focus {
  outline: 2px solid #000;
  outline-offset: -2px;
}

#onetrust-banner-sdk a:focus {
  outline: 2px solid #000;
}

#onetrust-banner-sdk .ot-close-icon,
#onetrust-pc-sdk .ot-close-icon,
#ot-sync-ntfy .ot-close-icon {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMzQ4LjMzM3B4IiBoZWlnaHQ9IjM0OC4zMzNweCIgdmlld0JveD0iMCAwIDM0OC4zMzMgMzQ4LjMzNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzQ4LjMzMyAzNDguMzM0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PHBhdGggZmlsbD0iIzU2NTY1NiIgZD0iTTMzNi41NTksNjguNjExTDIzMS4wMTYsMTc0LjE2NWwxMDUuNTQzLDEwNS41NDljMTUuNjk5LDE1LjcwNSwxNS42OTksNDEuMTQ1LDAsNTYuODVjLTcuODQ0LDcuODQ0LTE4LjEyOCwxMS43NjktMjguNDA3LDExLjc2OWMtMTAuMjk2LDAtMjAuNTgxLTMuOTE5LTI4LjQxOS0xMS43NjlMMTc0LjE2NywyMzEuMDAzTDY4LjYwOSwzMzYuNTYzYy03Ljg0Myw3Ljg0NC0xOC4xMjgsMTEuNzY5LTI4LjQxNiwxMS43NjljLTEwLjI4NSwwLTIwLjU2My0zLjkxOS0yOC40MTMtMTEuNzY5Yy0xNS42OTktMTUuNjk4LTE1LjY5OS00MS4xMzksMC01Ni44NWwxMDUuNTQtMTA1LjU0OUwxMS43NzQsNjguNjExYy0xNS42OTktMTUuNjk5LTE1LjY5OS00MS4xNDUsMC01Ni44NDRjMTUuNjk2LTE1LjY4Nyw0MS4xMjctMTUuNjg3LDU2LjgyOSwwbDEwNS41NjMsMTA1LjU1NEwyNzkuNzIxLDExLjc2N2MxNS43MDUtMTUuNjg3LDQxLjEzOS0xNS42ODcsNTYuODMyLDBDMzUyLjI1OCwyNy40NjYsMzUyLjI1OCw1Mi45MTIsMzM2LjU1OSw2OC42MTF6Ii8+PC9nPjwvc3ZnPg==");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 12px;
  width: 12px;
}

#onetrust-banner-sdk .powered-by-logo,
#onetrust-banner-sdk .ot-pc-footer-logo a,
#onetrust-pc-sdk .powered-by-logo,
#onetrust-pc-sdk .ot-pc-footer-logo a,
#ot-sync-ntfy .powered-by-logo,
#ot-sync-ntfy .ot-pc-footer-logo a {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 25px;
  width: 152px;
  display: block;
}

#onetrust-banner-sdk h3 *,
#onetrust-banner-sdk h4 *,
#onetrust-banner-sdk h6 *,
#onetrust-banner-sdk button *,
#onetrust-banner-sdk a[data-parent-id] *,
#onetrust-pc-sdk h3 *,
#onetrust-pc-sdk h4 *,
#onetrust-pc-sdk h6 *,
#onetrust-pc-sdk button *,
#onetrust-pc-sdk a[data-parent-id] *,
#ot-sync-ntfy h3 *,
#ot-sync-ntfy h4 *,
#ot-sync-ntfy h6 *,
#ot-sync-ntfy button *,
#ot-sync-ntfy a[data-parent-id] * {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}

#onetrust-banner-sdk .ot-hide,
#onetrust-pc-sdk .ot-hide,
#ot-sync-ntfy .ot-hide {
  display: none !important;
}

#onetrust-pc-sdk .ot-sdk-row .ot-sdk-column {
  padding: 0;
}

#onetrust-pc-sdk .ot-sdk-container {
  padding-right: 0;
}

#onetrust-pc-sdk .ot-sdk-row {
  flex-direction: initial;
  width: 100%;
}

#onetrust-pc-sdk [type="checkbox"]:checked,
#onetrust-pc-sdk [type="checkbox"]:not(:checked) {
  pointer-events: initial;
}

#onetrust-pc-sdk [type="checkbox"]:disabled + label::before,
#onetrust-pc-sdk [type="checkbox"]:disabled + label:after,
#onetrust-pc-sdk [type="checkbox"]:disabled + label {
  pointer-events: none;
  opacity: 0.7;
}

#onetrust-pc-sdk #vendor-list-content {
  transform: translate3d(0, 0, 0);
}

#onetrust-pc-sdk li input[type="checkbox"] {
  z-index: 1;
}

#onetrust-pc-sdk li .ot-checkbox label {
  z-index: 2;
}

#onetrust-pc-sdk li .ot-checkbox input[type="checkbox"] {
  height: auto;
  width: auto;
}

#onetrust-pc-sdk li .host-title a,
#onetrust-pc-sdk li .ot-host-name a,
#onetrust-pc-sdk li .accordion-text,
#onetrust-pc-sdk li .ot-acc-txt {
  z-index: 2;
  position: relative;
}

#onetrust-pc-sdk input {
  margin: 3px 0.1ex;
}

#onetrust-pc-sdk .toggle-always-active {
  opacity: 0.6;
  // cursor: default;
}

#onetrust-pc-sdk .pc-logo,
#onetrust-pc-sdk .ot-pc-logo {
  height: 60px;
  width: 180px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

#onetrust-pc-sdk .ot-tooltip .ot-tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

#onetrust-pc-sdk .ot-tooltip .ot-tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

#onetrust-pc-sdk .ot-tooltip:hover .ot-tooltiptext {
  visibility: visible;
  opacity: 1;
}

#onetrust-pc-sdk .ot-tooltip {
  position: relative;
  display: inline-block;
  z-index: 3;
}

#onetrust-pc-sdk .ot-tooltip svg {
  color: grey;
  height: 20px;
  width: 20px;
}

#onetrust-pc-sdk .screen-reader-only,
#onetrust-pc-sdk .ot-scrn-rdr,
.ot-sdk-cookie-policy .screen-reader-only,
.ot-sdk-cookie-policy .ot-scrn-rdr {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

#onetrust-pc-sdk.ot-fade-in,
.onetrust-pc-dark-filter.ot-fade-in,
#onetrust-banner-sdk.ot-fade-in {
  animation-name: onetrust-fade-in;
  animation-duration: 400ms;
  animation-timing-function: ease-in-out;
}

#onetrust-pc-sdk.ot-hide {
  display: none !important;
}

.onetrust-pc-dark-filter.ot-hide {
  display: none !important;
}

#ot-sdk-btn.ot-sdk-show-settings,
#ot-sdk-btn.optanon-show-settings {
  color: #68b631;
  border: 1px solid #68b631;
  height: auto;
  white-space: normal;
  word-wrap: break-word;
  padding: 0.8em 2em;
  font-size: 0.8em;
  line-height: 1.2;
  cursor: pointer;
  -moz-transition: 0.1s ease;
  -o-transition: 0.1s ease;
  -webkit-transition: 1s ease;
  transition: 0.1s ease;
}

#ot-sdk-btn.ot-sdk-show-settings:hover,
#ot-sdk-btn.optanon-show-settings:hover {
  color: #fff;
  background-color: #68b631;
}

.onetrust-pc-dark-filter {
  background: rgba(0, 0, 0, 0.5);
  z-index: 2147483646;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
}

@keyframes onetrust-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media only screen and (min-width: 426px) and (max-width: 896px) and (orientation: landscape) {
  #onetrust-pc-sdk p {
    font-size: 0.75em;
  }
}

#onetrust-banner-sdk .banner-option-input:focus + label {
  outline: 1px solid #000;
  outline-style: auto;
}

#onetrust-banner-sdk,
#onetrust-pc-sdk,
#ot-sdk-cookie-policy,
#ot-sync-ntfy {
  font-size: 16px;
}

#onetrust-banner-sdk *,
#onetrust-banner-sdk ::after,
#onetrust-banner-sdk ::before,
#onetrust-pc-sdk *,
#onetrust-pc-sdk ::after,
#onetrust-pc-sdk ::before,
#ot-sdk-cookie-policy *,
#ot-sdk-cookie-policy ::after,
#ot-sdk-cookie-policy ::before,
#ot-sync-ntfy *,
#ot-sync-ntfy ::after,
#ot-sync-ntfy ::before {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

#onetrust-banner-sdk div,
#onetrust-banner-sdk span,
#onetrust-banner-sdk h1,
#onetrust-banner-sdk h2,
#onetrust-banner-sdk h3,
#onetrust-banner-sdk h4,
#onetrust-banner-sdk h5,
#onetrust-banner-sdk h6,
#onetrust-banner-sdk p,
#onetrust-banner-sdk img,
#onetrust-banner-sdk svg,
#onetrust-banner-sdk button,
#onetrust-banner-sdk section,
#onetrust-banner-sdk a,
#onetrust-banner-sdk label,
#onetrust-banner-sdk input,
#onetrust-banner-sdk ul,
#onetrust-banner-sdk li,
#onetrust-banner-sdk nav,
#onetrust-banner-sdk table,
#onetrust-banner-sdk thead,
#onetrust-banner-sdk tr,
#onetrust-banner-sdk td,
#onetrust-banner-sdk tbody,
#onetrust-banner-sdk .ot-main-content,
#onetrust-banner-sdk .ot-toggle,
#onetrust-banner-sdk #ot-content,
#onetrust-banner-sdk #ot-pc-content,
#onetrust-banner-sdk .checkbox,
#onetrust-pc-sdk div,
#onetrust-pc-sdk span,
#onetrust-pc-sdk h1,
#onetrust-pc-sdk h2,
#onetrust-pc-sdk h3,
#onetrust-pc-sdk h4,
#onetrust-pc-sdk h5,
#onetrust-pc-sdk h6,
#onetrust-pc-sdk p,
#onetrust-pc-sdk img,
#onetrust-pc-sdk svg,
#onetrust-pc-sdk button,
#onetrust-pc-sdk section,
#onetrust-pc-sdk a,
#onetrust-pc-sdk label,
#onetrust-pc-sdk input,
#onetrust-pc-sdk ul,
#onetrust-pc-sdk li,
#onetrust-pc-sdk nav,
#onetrust-pc-sdk table,
#onetrust-pc-sdk thead,
#onetrust-pc-sdk tr,
#onetrust-pc-sdk td,
#onetrust-pc-sdk tbody,
#onetrust-pc-sdk .ot-main-content,
#onetrust-pc-sdk .ot-toggle,
#onetrust-pc-sdk #ot-content,
#onetrust-pc-sdk #ot-pc-content,
#onetrust-pc-sdk .checkbox,
#ot-sdk-cookie-policy div,
#ot-sdk-cookie-policy span,
#ot-sdk-cookie-policy h1,
#ot-sdk-cookie-policy h2,
#ot-sdk-cookie-policy h3,
#ot-sdk-cookie-policy h4,
#ot-sdk-cookie-policy h5,
#ot-sdk-cookie-policy h6,
#ot-sdk-cookie-policy p,
#ot-sdk-cookie-policy img,
#ot-sdk-cookie-policy svg,
#ot-sdk-cookie-policy button,
#ot-sdk-cookie-policy section,
#ot-sdk-cookie-policy a,
#ot-sdk-cookie-policy label,
#ot-sdk-cookie-policy input,
#ot-sdk-cookie-policy ul,
#ot-sdk-cookie-policy li,
#ot-sdk-cookie-policy nav,
#ot-sdk-cookie-policy table,
#ot-sdk-cookie-policy thead,
#ot-sdk-cookie-policy tr,
#ot-sdk-cookie-policy td,
#ot-sdk-cookie-policy tbody,
#ot-sdk-cookie-policy .ot-main-content,
#ot-sdk-cookie-policy .ot-toggle,
#ot-sdk-cookie-policy #ot-content,
#ot-sdk-cookie-policy #ot-pc-content,
#ot-sdk-cookie-policy .checkbox,
#ot-sync-ntfy div,
#ot-sync-ntfy span,
#ot-sync-ntfy h1,
#ot-sync-ntfy h2,
#ot-sync-ntfy h3,
#ot-sync-ntfy h4,
#ot-sync-ntfy h5,
#ot-sync-ntfy h6,
#ot-sync-ntfy p,
#ot-sync-ntfy img,
#ot-sync-ntfy svg,
#ot-sync-ntfy button,
#ot-sync-ntfy section,
#ot-sync-ntfy a,
#ot-sync-ntfy label,
#ot-sync-ntfy input,
#ot-sync-ntfy ul,
#ot-sync-ntfy li,
#ot-sync-ntfy nav,
#ot-sync-ntfy table,
#ot-sync-ntfy thead,
#ot-sync-ntfy tr,
#ot-sync-ntfy td,
#ot-sync-ntfy tbody,
#ot-sync-ntfy .ot-main-content,
#ot-sync-ntfy .ot-toggle,
#ot-sync-ntfy #ot-content,
#ot-sync-ntfy #ot-pc-content,
#ot-sync-ntfy .checkbox {
  font-family: inherit;
  font-weight: normal;
  -webkit-font-smoothing: auto;
  letter-spacing: normal;
  line-height: normal;
  padding: 0;
  margin: 0;
  height: auto;
  min-height: 0;
  max-height: none;
  width: auto;
  min-width: 0;
  max-width: none;
  border-radius: 0;
  border: none;
  clear: none;
  float: none;
  position: static;
  bottom: auto;
  left: auto;
  right: auto;
  top: auto;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  background: none;
  overflow: visible;
  vertical-align: baseline;
  visibility: visible;
  z-index: auto;
  box-shadow: none;
}

#onetrust-banner-sdk label:before,
#onetrust-banner-sdk label:after,
#onetrust-banner-sdk .checkbox:after,
#onetrust-banner-sdk .checkbox:before,
#onetrust-pc-sdk label:before,
#onetrust-pc-sdk label:after,
#onetrust-pc-sdk .checkbox:after,
#onetrust-pc-sdk .checkbox:before,
#ot-sdk-cookie-policy label:before,
#ot-sdk-cookie-policy label:after,
#ot-sdk-cookie-policy .checkbox:after,
#ot-sdk-cookie-policy .checkbox:before,
#ot-sync-ntfy label:before,
#ot-sync-ntfy label:after,
#ot-sync-ntfy .checkbox:after,
#ot-sync-ntfy .checkbox:before {
  content: "";
  content: none;
}

#onetrust-banner-sdk .ot-sdk-container,
#onetrust-pc-sdk .ot-sdk-container,
#ot-sdk-cookie-policy .ot-sdk-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}

#onetrust-banner-sdk .ot-sdk-column,
#onetrust-banner-sdk .ot-sdk-columns,
#onetrust-pc-sdk .ot-sdk-column,
#onetrust-pc-sdk .ot-sdk-columns,
#ot-sdk-cookie-policy .ot-sdk-column,
#ot-sdk-cookie-policy .ot-sdk-columns {
  width: 100%;
  float: left;
  box-sizing: border-box;
  padding: 0;
  display: initial;
}

@media (min-width: 400px) {
  #onetrust-banner-sdk .ot-sdk-container,
  #onetrust-pc-sdk .ot-sdk-container,
  #ot-sdk-cookie-policy .ot-sdk-container {
    width: 90%;
    padding: 0;
  }
}

@media (min-width: 550px) {
  #onetrust-banner-sdk .ot-sdk-container,
  #onetrust-pc-sdk .ot-sdk-container,
  #ot-sdk-cookie-policy .ot-sdk-container {
    width: 100%;
  }

  #onetrust-banner-sdk .ot-sdk-column,
  #onetrust-banner-sdk .ot-sdk-columns,
  #onetrust-pc-sdk .ot-sdk-column,
  #onetrust-pc-sdk .ot-sdk-columns,
  #ot-sdk-cookie-policy .ot-sdk-column,
  #ot-sdk-cookie-policy .ot-sdk-columns {
    margin-left: 4%;
  }

  #onetrust-banner-sdk .ot-sdk-column:first-child,
  #onetrust-banner-sdk .ot-sdk-columns:first-child,
  #onetrust-pc-sdk .ot-sdk-column:first-child,
  #onetrust-pc-sdk .ot-sdk-columns:first-child,
  #ot-sdk-cookie-policy .ot-sdk-column:first-child,
  #ot-sdk-cookie-policy .ot-sdk-columns:first-child {
    margin-left: 0;
  }

  #onetrust-banner-sdk .ot-sdk-one.ot-sdk-column,
  #onetrust-banner-sdk .ot-sdk-one.ot-sdk-columns,
  #onetrust-pc-sdk .ot-sdk-one.ot-sdk-column,
  #onetrust-pc-sdk .ot-sdk-one.ot-sdk-columns,
  #ot-sdk-cookie-policy .ot-sdk-one.ot-sdk-column,
  #ot-sdk-cookie-policy .ot-sdk-one.ot-sdk-columns {
    width: 4.66666666667%;
  }

  #onetrust-banner-sdk .ot-sdk-two.ot-sdk-columns,
  #onetrust-pc-sdk .ot-sdk-two.ot-sdk-columns,
  #ot-sdk-cookie-policy .ot-sdk-two.ot-sdk-columns {
    width: 13.3333333333%;
  }

  #onetrust-banner-sdk .ot-sdk-three.ot-sdk-columns,
  #onetrust-pc-sdk .ot-sdk-three.ot-sdk-columns,
  #ot-sdk-cookie-policy .ot-sdk-three.ot-sdk-columns {
    width: 22%;
  }

  #onetrust-banner-sdk .ot-sdk-four.ot-sdk-columns,
  #onetrust-pc-sdk .ot-sdk-four.ot-sdk-columns,
  #ot-sdk-cookie-policy .ot-sdk-four.ot-sdk-columns {
    width: 30.6666666667%;
  }

  #onetrust-banner-sdk .ot-sdk-five.ot-sdk-columns,
  #onetrust-pc-sdk .ot-sdk-five.ot-sdk-columns,
  #ot-sdk-cookie-policy .ot-sdk-five.ot-sdk-columns {
    width: 39.3333333333%;
  }

  #onetrust-banner-sdk .ot-sdk-six.ot-sdk-columns,
  #onetrust-pc-sdk .ot-sdk-six.ot-sdk-columns,
  #ot-sdk-cookie-policy .ot-sdk-six.ot-sdk-columns {
    width: 48%;
  }

  #onetrust-banner-sdk .ot-sdk-seven.ot-sdk-columns,
  #onetrust-pc-sdk .ot-sdk-seven.ot-sdk-columns,
  #ot-sdk-cookie-policy .ot-sdk-seven.ot-sdk-columns {
    width: 56.6666666667%;
  }

  #onetrust-banner-sdk .ot-sdk-eight.ot-sdk-columns,
  #onetrust-pc-sdk .ot-sdk-eight.ot-sdk-columns,
  #ot-sdk-cookie-policy .ot-sdk-eight.ot-sdk-columns {
    width: 65.3333333333%;
  }

  #onetrust-banner-sdk .ot-sdk-nine.ot-sdk-columns,
  #onetrust-pc-sdk .ot-sdk-nine.ot-sdk-columns,
  #ot-sdk-cookie-policy .ot-sdk-nine.ot-sdk-columns {
    width: 74%;
  }

  #onetrust-banner-sdk .ot-sdk-ten.ot-sdk-columns,
  #onetrust-pc-sdk .ot-sdk-ten.ot-sdk-columns,
  #ot-sdk-cookie-policy .ot-sdk-ten.ot-sdk-columns {
    width: 82.6666666667%;
  }

  #onetrust-banner-sdk .ot-sdk-eleven.ot-sdk-columns,
  #onetrust-pc-sdk .ot-sdk-eleven.ot-sdk-columns,
  #ot-sdk-cookie-policy .ot-sdk-eleven.ot-sdk-columns {
    width: 91.3333333333%;
  }

  #onetrust-banner-sdk .ot-sdk-twelve.ot-sdk-columns,
  #onetrust-pc-sdk .ot-sdk-twelve.ot-sdk-columns,
  #ot-sdk-cookie-policy .ot-sdk-twelve.ot-sdk-columns {
    width: 100%;
    margin-left: 0;
  }

  #onetrust-banner-sdk .ot-sdk-one-third.ot-sdk-column,
  #onetrust-pc-sdk .ot-sdk-one-third.ot-sdk-column,
  #ot-sdk-cookie-policy .ot-sdk-one-third.ot-sdk-column {
    width: 30.6666666667%;
  }

  #onetrust-banner-sdk .ot-sdk-two-thirds.ot-sdk-column,
  #onetrust-pc-sdk .ot-sdk-two-thirds.ot-sdk-column,
  #ot-sdk-cookie-policy .ot-sdk-two-thirds.ot-sdk-column {
    width: 65.3333333333%;
  }

  #onetrust-banner-sdk .ot-sdk-one-half.ot-sdk-column,
  #onetrust-pc-sdk .ot-sdk-one-half.ot-sdk-column,
  #ot-sdk-cookie-policy .ot-sdk-one-half.ot-sdk-column {
    width: 48%;
  }

  #onetrust-banner-sdk .ot-sdk-offset-by-one.ot-sdk-column,
  #onetrust-banner-sdk .ot-sdk-offset-by-one.ot-sdk-columns,
  #onetrust-pc-sdk .ot-sdk-offset-by-one.ot-sdk-column,
  #onetrust-pc-sdk .ot-sdk-offset-by-one.ot-sdk-columns,
  #ot-sdk-cookie-policy .ot-sdk-offset-by-one.ot-sdk-column,
  #ot-sdk-cookie-policy .ot-sdk-offset-by-one.ot-sdk-columns {
    margin-left: 8.66666666667%;
  }

  #onetrust-banner-sdk .ot-sdk-offset-by-two.ot-sdk-column,
  #onetrust-banner-sdk .ot-sdk-offset-by-two.ot-sdk-columns,
  #onetrust-pc-sdk .ot-sdk-offset-by-two.ot-sdk-column,
  #onetrust-pc-sdk .ot-sdk-offset-by-two.ot-sdk-columns,
  #ot-sdk-cookie-policy .ot-sdk-offset-by-two.ot-sdk-column,
  #ot-sdk-cookie-policy .ot-sdk-offset-by-two.ot-sdk-columns {
    margin-left: 17.3333333333%;
  }

  #onetrust-banner-sdk .ot-sdk-offset-by-three.ot-sdk-column,
  #onetrust-banner-sdk .ot-sdk-offset-by-three.ot-sdk-columns,
  #onetrust-pc-sdk .ot-sdk-offset-by-three.ot-sdk-column,
  #onetrust-pc-sdk .ot-sdk-offset-by-three.ot-sdk-columns,
  #ot-sdk-cookie-policy .ot-sdk-offset-by-three.ot-sdk-column,
  #ot-sdk-cookie-policy .ot-sdk-offset-by-three.ot-sdk-columns {
    margin-left: 26%;
  }

  #onetrust-banner-sdk .ot-sdk-offset-by-four.ot-sdk-column,
  #onetrust-banner-sdk .ot-sdk-offset-by-four.ot-sdk-columns,
  #onetrust-pc-sdk .ot-sdk-offset-by-four.ot-sdk-column,
  #onetrust-pc-sdk .ot-sdk-offset-by-four.ot-sdk-columns,
  #ot-sdk-cookie-policy .ot-sdk-offset-by-four.ot-sdk-column,
  #ot-sdk-cookie-policy .ot-sdk-offset-by-four.ot-sdk-columns {
    margin-left: 34.6666666667%;
  }

  #onetrust-banner-sdk .ot-sdk-offset-by-five.ot-sdk-column,
  #onetrust-banner-sdk .ot-sdk-offset-by-five.ot-sdk-columns,
  #onetrust-pc-sdk .ot-sdk-offset-by-five.ot-sdk-column,
  #onetrust-pc-sdk .ot-sdk-offset-by-five.ot-sdk-columns,
  #ot-sdk-cookie-policy .ot-sdk-offset-by-five.ot-sdk-column,
  #ot-sdk-cookie-policy .ot-sdk-offset-by-five.ot-sdk-columns {
    margin-left: 43.3333333333%;
  }

  #onetrust-banner-sdk .ot-sdk-offset-by-six.ot-sdk-column,
  #onetrust-banner-sdk .ot-sdk-offset-by-six.ot-sdk-columns,
  #onetrust-pc-sdk .ot-sdk-offset-by-six.ot-sdk-column,
  #onetrust-pc-sdk .ot-sdk-offset-by-six.ot-sdk-columns,
  #ot-sdk-cookie-policy .ot-sdk-offset-by-six.ot-sdk-column,
  #ot-sdk-cookie-policy .ot-sdk-offset-by-six.ot-sdk-columns {
    margin-left: 52%;
  }

  #onetrust-banner-sdk .ot-sdk-offset-by-seven.ot-sdk-column,
  #onetrust-banner-sdk .ot-sdk-offset-by-seven.ot-sdk-columns,
  #onetrust-pc-sdk .ot-sdk-offset-by-seven.ot-sdk-column,
  #onetrust-pc-sdk .ot-sdk-offset-by-seven.ot-sdk-columns,
  #ot-sdk-cookie-policy .ot-sdk-offset-by-seven.ot-sdk-column,
  #ot-sdk-cookie-policy .ot-sdk-offset-by-seven.ot-sdk-columns {
    margin-left: 60.6666666667%;
  }

  #onetrust-banner-sdk .ot-sdk-offset-by-eight.ot-sdk-column,
  #onetrust-banner-sdk .ot-sdk-offset-by-eight.ot-sdk-columns,
  #onetrust-pc-sdk .ot-sdk-offset-by-eight.ot-sdk-column,
  #onetrust-pc-sdk .ot-sdk-offset-by-eight.ot-sdk-columns,
  #ot-sdk-cookie-policy .ot-sdk-offset-by-eight.ot-sdk-column,
  #ot-sdk-cookie-policy .ot-sdk-offset-by-eight.ot-sdk-columns {
    margin-left: 69.3333333333%;
  }

  #onetrust-banner-sdk .ot-sdk-offset-by-nine.ot-sdk-column,
  #onetrust-banner-sdk .ot-sdk-offset-by-nine.ot-sdk-columns,
  #onetrust-pc-sdk .ot-sdk-offset-by-nine.ot-sdk-column,
  #onetrust-pc-sdk .ot-sdk-offset-by-nine.ot-sdk-columns,
  #ot-sdk-cookie-policy .ot-sdk-offset-by-nine.ot-sdk-column,
  #ot-sdk-cookie-policy .ot-sdk-offset-by-nine.ot-sdk-columns {
    margin-left: 78%;
  }

  #onetrust-banner-sdk .ot-sdk-offset-by-ten.ot-sdk-column,
  #onetrust-banner-sdk .ot-sdk-offset-by-ten.ot-sdk-columns,
  #onetrust-pc-sdk .ot-sdk-offset-by-ten.ot-sdk-column,
  #onetrust-pc-sdk .ot-sdk-offset-by-ten.ot-sdk-columns,
  #ot-sdk-cookie-policy .ot-sdk-offset-by-ten.ot-sdk-column,
  #ot-sdk-cookie-policy .ot-sdk-offset-by-ten.ot-sdk-columns {
    margin-left: 86.6666666667%;
  }

  #onetrust-banner-sdk .ot-sdk-offset-by-eleven.ot-sdk-column,
  #onetrust-banner-sdk .ot-sdk-offset-by-eleven.ot-sdk-columns,
  #onetrust-pc-sdk .ot-sdk-offset-by-eleven.ot-sdk-column,
  #onetrust-pc-sdk .ot-sdk-offset-by-eleven.ot-sdk-columns,
  #ot-sdk-cookie-policy .ot-sdk-offset-by-eleven.ot-sdk-column,
  #ot-sdk-cookie-policy .ot-sdk-offset-by-eleven.ot-sdk-columns {
    margin-left: 95.3333333333%;
  }

  #onetrust-banner-sdk .ot-sdk-offset-by-one-third.ot-sdk-column,
  #onetrust-banner-sdk .ot-sdk-offset-by-one-third.ot-sdk-columns,
  #onetrust-pc-sdk .ot-sdk-offset-by-one-third.ot-sdk-column,
  #onetrust-pc-sdk .ot-sdk-offset-by-one-third.ot-sdk-columns,
  #ot-sdk-cookie-policy .ot-sdk-offset-by-one-third.ot-sdk-column,
  #ot-sdk-cookie-policy .ot-sdk-offset-by-one-third.ot-sdk-columns {
    margin-left: 34.6666666667%;
  }

  #onetrust-banner-sdk .ot-sdk-offset-by-two-thirds.ot-sdk-column,
  #onetrust-banner-sdk .ot-sdk-offset-by-two-thirds.ot-sdk-columns,
  #onetrust-pc-sdk .ot-sdk-offset-by-two-thirds.ot-sdk-column,
  #onetrust-pc-sdk .ot-sdk-offset-by-two-thirds.ot-sdk-columns,
  #ot-sdk-cookie-policy .ot-sdk-offset-by-two-thirds.ot-sdk-column,
  #ot-sdk-cookie-policy .ot-sdk-offset-by-two-thirds.ot-sdk-columns {
    margin-left: 69.3333333333%;
  }

  #onetrust-banner-sdk .ot-sdk-offset-by-one-half.ot-sdk-column,
  #onetrust-banner-sdk .ot-sdk-offset-by-one-half.ot-sdk-columns,
  #onetrust-pc-sdk .ot-sdk-offset-by-one-half.ot-sdk-column,
  #onetrust-pc-sdk .ot-sdk-offset-by-one-half.ot-sdk-columns,
  #ot-sdk-cookie-policy .ot-sdk-offset-by-one-half.ot-sdk-column,
  #ot-sdk-cookie-policy .ot-sdk-offset-by-one-half.ot-sdk-columns {
    margin-left: 52%;
  }
}

#onetrust-banner-sdk h1,
#onetrust-banner-sdk h2,
#onetrust-banner-sdk h3,
#onetrust-banner-sdk h4,
#onetrust-banner-sdk h5,
#onetrust-banner-sdk h6,
#onetrust-pc-sdk h1,
#onetrust-pc-sdk h2,
#onetrust-pc-sdk h3,
#onetrust-pc-sdk h4,
#onetrust-pc-sdk h5,
#onetrust-pc-sdk h6,
#ot-sdk-cookie-policy h1,
#ot-sdk-cookie-policy h2,
#ot-sdk-cookie-policy h3,
#ot-sdk-cookie-policy h4,
#ot-sdk-cookie-policy h5,
#ot-sdk-cookie-policy h6 {
  margin-top: 0;
  font-weight: 600;
  font-family: inherit;
}

#onetrust-banner-sdk h1,
#onetrust-pc-sdk h1,
#ot-sdk-cookie-policy h1 {
  font-size: 1.5rem;
  line-height: 1.2;
}

#onetrust-banner-sdk h2,
#onetrust-pc-sdk h2,
#ot-sdk-cookie-policy h2 {
  font-size: 1.5rem;
  line-height: 1.25;
}

#onetrust-banner-sdk h3,
#onetrust-pc-sdk h3,
#ot-sdk-cookie-policy h3 {
  font-size: 1.5rem;
  line-height: 1.3;
}

#onetrust-banner-sdk h4,
#onetrust-pc-sdk h4,
#ot-sdk-cookie-policy h4 {
  font-size: 1.5rem;
  line-height: 1.35;
}

#onetrust-banner-sdk h5,
#onetrust-pc-sdk h5,
#ot-sdk-cookie-policy h5 {
  font-size: 1.5rem;
  line-height: 1.5;
}

#onetrust-banner-sdk h6,
#onetrust-pc-sdk h6,
#ot-sdk-cookie-policy h6 {
  font-size: 1.5rem;
  line-height: 1.6;
}

@media (min-width: 550px) {
  #onetrust-banner-sdk h1,
  #onetrust-pc-sdk h1,
  #ot-sdk-cookie-policy h1 {
    font-size: 1.5rem;
  }

  #onetrust-banner-sdk h2,
  #onetrust-pc-sdk h2,
  #ot-sdk-cookie-policy h2 {
    font-size: 1.5rem;
  }

  #onetrust-banner-sdk h3,
  #onetrust-pc-sdk h3,
  #ot-sdk-cookie-policy h3 {
    font-size: 1.5rem;
  }

  #onetrust-banner-sdk h4,
  #onetrust-pc-sdk h4,
  #ot-sdk-cookie-policy h4 {
    font-size: 1.5rem;
  }

  #onetrust-banner-sdk h5,
  #onetrust-pc-sdk h5,
  #ot-sdk-cookie-policy h5 {
    font-size: 1.5rem;
  }

  #onetrust-banner-sdk h6,
  #onetrust-pc-sdk h6,
  #ot-sdk-cookie-policy h6 {
    font-size: 1.5rem;
  }
}

#onetrust-banner-sdk p,
#onetrust-pc-sdk p,
#ot-sdk-cookie-policy p {
  margin: 0 0 1em 0;
  font-family: inherit;
  line-height: normal;
}

#onetrust-banner-sdk a,
#onetrust-pc-sdk a,
#ot-sdk-cookie-policy a {
  color: #565656;
  text-decoration: underline;
}

#onetrust-banner-sdk a:hover,
#onetrust-pc-sdk a:hover,
#ot-sdk-cookie-policy a:hover {
  color: #565656;
  text-decoration: none;
}

#onetrust-banner-sdk .ot-sdk-button,
#onetrust-banner-sdk button,
#onetrust-pc-sdk .ot-sdk-button,
#onetrust-pc-sdk button,
#ot-sdk-cookie-policy .ot-sdk-button,
#ot-sdk-cookie-policy button {
  margin-bottom: 1rem;
  font-family: inherit;
}

#onetrust-banner-sdk .ot-sdk-button,
#onetrust-banner-sdk button,
#onetrust-banner-sdk input[type="submit"],
#onetrust-banner-sdk input[type="reset"],
#onetrust-banner-sdk input[type="button"],
#onetrust-pc-sdk .ot-sdk-button,
#onetrust-pc-sdk button,
#onetrust-pc-sdk input[type="submit"],
#onetrust-pc-sdk input[type="reset"],
#onetrust-pc-sdk input[type="button"],
#ot-sdk-cookie-policy .ot-sdk-button,
#ot-sdk-cookie-policy button,
#ot-sdk-cookie-policy input[type="submit"],
#ot-sdk-cookie-policy input[type="reset"],
#ot-sdk-cookie-policy input[type="button"] {
  display: inline-block;
  height: 38px;
  padding: 0 30px;
  color: #555;
  text-align: center;
  font-size: 0.9em;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0.01em;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid #bbb;
  cursor: pointer;
  box-sizing: border-box;
}

#onetrust-banner-sdk .ot-sdk-button:hover,
#onetrust-banner-sdk :not(.ot-leg-btn-container) > button:hover,
#onetrust-banner-sdk input[type="submit"]:hover,
#onetrust-banner-sdk input[type="reset"]:hover,
#onetrust-banner-sdk input[type="button"]:hover,
#onetrust-banner-sdk .ot-sdk-button:focus,
#onetrust-banner-sdk :not(.ot-leg-btn-container) > button:focus,
#onetrust-banner-sdk input[type="submit"]:focus,
#onetrust-banner-sdk input[type="reset"]:focus,
#onetrust-banner-sdk input[type="button"]:focus,
#onetrust-pc-sdk .ot-sdk-button:hover,
#onetrust-pc-sdk :not(.ot-leg-btn-container) > button:hover,
#onetrust-pc-sdk input[type="submit"]:hover,
#onetrust-pc-sdk input[type="reset"]:hover,
#onetrust-pc-sdk input[type="button"]:hover,
#onetrust-pc-sdk .ot-sdk-button:focus,
#onetrust-pc-sdk :not(.ot-leg-btn-container) > button:focus,
#onetrust-pc-sdk input[type="submit"]:focus,
#onetrust-pc-sdk input[type="reset"]:focus,
#onetrust-pc-sdk input[type="button"]:focus,
#ot-sdk-cookie-policy .ot-sdk-button:hover,
#ot-sdk-cookie-policy :not(.ot-leg-btn-container) > button:hover,
#ot-sdk-cookie-policy input[type="submit"]:hover,
#ot-sdk-cookie-policy input[type="reset"]:hover,
#ot-sdk-cookie-policy input[type="button"]:hover,
#ot-sdk-cookie-policy .ot-sdk-button:focus,
#ot-sdk-cookie-policy :not(.ot-leg-btn-container) > button:focus,
#ot-sdk-cookie-policy input[type="submit"]:focus,
#ot-sdk-cookie-policy input[type="reset"]:focus,
#ot-sdk-cookie-policy input[type="button"]:focus {
  color: #333;
  border-color: #888;
  opacity: 0.7;
}

#onetrust-banner-sdk .ot-sdk-button:focus,
#onetrust-banner-sdk :not(.ot-leg-btn-container) > button:focus,
#onetrust-banner-sdk input[type="submit"]:focus,
#onetrust-banner-sdk input[type="reset"]:focus,
#onetrust-banner-sdk input[type="button"]:focus,
#onetrust-pc-sdk .ot-sdk-button:focus,
#onetrust-pc-sdk :not(.ot-leg-btn-container) > button:focus,
#onetrust-pc-sdk input[type="submit"]:focus,
#onetrust-pc-sdk input[type="reset"]:focus,
#onetrust-pc-sdk input[type="button"]:focus,
#ot-sdk-cookie-policy .ot-sdk-button:focus,
#ot-sdk-cookie-policy :not(.ot-leg-btn-container) > button:focus,
#ot-sdk-cookie-policy input[type="submit"]:focus,
#ot-sdk-cookie-policy input[type="reset"]:focus,
#ot-sdk-cookie-policy input[type="button"]:focus {
  outline: 2px solid #000;
}

#onetrust-banner-sdk .ot-sdk-button.ot-sdk-button-primary,
#onetrust-banner-sdk button.ot-sdk-button-primary,
#onetrust-banner-sdk input[type="submit"].ot-sdk-button-primary,
#onetrust-banner-sdk input[type="reset"].ot-sdk-button-primary,
#onetrust-banner-sdk input[type="button"].ot-sdk-button-primary,
#onetrust-pc-sdk .ot-sdk-button.ot-sdk-button-primary,
#onetrust-pc-sdk button.ot-sdk-button-primary,
#onetrust-pc-sdk input[type="submit"].ot-sdk-button-primary,
#onetrust-pc-sdk input[type="reset"].ot-sdk-button-primary,
#onetrust-pc-sdk input[type="button"].ot-sdk-button-primary,
#ot-sdk-cookie-policy .ot-sdk-button.ot-sdk-button-primary,
#ot-sdk-cookie-policy button.ot-sdk-button-primary,
#ot-sdk-cookie-policy input[type="submit"].ot-sdk-button-primary,
#ot-sdk-cookie-policy input[type="reset"].ot-sdk-button-primary,
#ot-sdk-cookie-policy input[type="button"].ot-sdk-button-primary {
  color: #fff;
  background-color: #33c3f0;
  border-color: #33c3f0;
}

#onetrust-banner-sdk .ot-sdk-button.ot-sdk-button-primary:hover,
#onetrust-banner-sdk button.ot-sdk-button-primary:hover,
#onetrust-banner-sdk input[type="submit"].ot-sdk-button-primary:hover,
#onetrust-banner-sdk input[type="reset"].ot-sdk-button-primary:hover,
#onetrust-banner-sdk input[type="button"].ot-sdk-button-primary:hover,
#onetrust-banner-sdk .ot-sdk-button.ot-sdk-button-primary:focus,
#onetrust-banner-sdk button.ot-sdk-button-primary:focus,
#onetrust-banner-sdk input[type="submit"].ot-sdk-button-primary:focus,
#onetrust-banner-sdk input[type="reset"].ot-sdk-button-primary:focus,
#onetrust-banner-sdk input[type="button"].ot-sdk-button-primary:focus,
#onetrust-pc-sdk .ot-sdk-button.ot-sdk-button-primary:hover,
#onetrust-pc-sdk button.ot-sdk-button-primary:hover,
#onetrust-pc-sdk input[type="submit"].ot-sdk-button-primary:hover,
#onetrust-pc-sdk input[type="reset"].ot-sdk-button-primary:hover,
#onetrust-pc-sdk input[type="button"].ot-sdk-button-primary:hover,
#onetrust-pc-sdk .ot-sdk-button.ot-sdk-button-primary:focus,
#onetrust-pc-sdk button.ot-sdk-button-primary:focus,
#onetrust-pc-sdk input[type="submit"].ot-sdk-button-primary:focus,
#onetrust-pc-sdk input[type="reset"].ot-sdk-button-primary:focus,
#onetrust-pc-sdk input[type="button"].ot-sdk-button-primary:focus,
#ot-sdk-cookie-policy .ot-sdk-button.ot-sdk-button-primary:hover,
#ot-sdk-cookie-policy button.ot-sdk-button-primary:hover,
#ot-sdk-cookie-policy input[type="submit"].ot-sdk-button-primary:hover,
#ot-sdk-cookie-policy input[type="reset"].ot-sdk-button-primary:hover,
#ot-sdk-cookie-policy input[type="button"].ot-sdk-button-primary:hover,
#ot-sdk-cookie-policy .ot-sdk-button.ot-sdk-button-primary:focus,
#ot-sdk-cookie-policy button.ot-sdk-button-primary:focus,
#ot-sdk-cookie-policy input[type="submit"].ot-sdk-button-primary:focus,
#ot-sdk-cookie-policy input[type="reset"].ot-sdk-button-primary:focus,
#ot-sdk-cookie-policy input[type="button"].ot-sdk-button-primary:focus {
  color: #fff;
  background-color: #1eaedb;
  border-color: #1eaedb;
}

#onetrust-banner-sdk input[type="email"],
#onetrust-banner-sdk input[type="number"],
#onetrust-banner-sdk input[type="search"],
#onetrust-banner-sdk input[type="text"],
#onetrust-banner-sdk input[type="tel"],
#onetrust-banner-sdk input[type="url"],
#onetrust-banner-sdk input[type="password"],
#onetrust-banner-sdk textarea,
#onetrust-banner-sdk select,
#onetrust-pc-sdk input[type="email"],
#onetrust-pc-sdk input[type="number"],
#onetrust-pc-sdk input[type="search"],
#onetrust-pc-sdk input[type="text"],
#onetrust-pc-sdk input[type="tel"],
#onetrust-pc-sdk input[type="url"],
#onetrust-pc-sdk input[type="password"],
#onetrust-pc-sdk textarea,
#onetrust-pc-sdk select,
#ot-sdk-cookie-policy input[type="email"],
#ot-sdk-cookie-policy input[type="number"],
#ot-sdk-cookie-policy input[type="search"],
#ot-sdk-cookie-policy input[type="text"],
#ot-sdk-cookie-policy input[type="tel"],
#ot-sdk-cookie-policy input[type="url"],
#ot-sdk-cookie-policy input[type="password"],
#ot-sdk-cookie-policy textarea,
#ot-sdk-cookie-policy select {
  height: 38px;
  padding: 6px 10px;
  background-color: #fff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  box-shadow: none;
  box-sizing: border-box;
}

#onetrust-banner-sdk input[type="email"],
#onetrust-banner-sdk input[type="number"],
#onetrust-banner-sdk input[type="search"],
#onetrust-banner-sdk input[type="text"],
#onetrust-banner-sdk input[type="tel"],
#onetrust-banner-sdk input[type="url"],
#onetrust-banner-sdk input[type="password"],
#onetrust-banner-sdk textarea,
#onetrust-pc-sdk input[type="email"],
#onetrust-pc-sdk input[type="number"],
#onetrust-pc-sdk input[type="search"],
#onetrust-pc-sdk input[type="text"],
#onetrust-pc-sdk input[type="tel"],
#onetrust-pc-sdk input[type="url"],
#onetrust-pc-sdk input[type="password"],
#onetrust-pc-sdk textarea,
#ot-sdk-cookie-policy input[type="email"],
#ot-sdk-cookie-policy input[type="number"],
#ot-sdk-cookie-policy input[type="search"],
#ot-sdk-cookie-policy input[type="text"],
#ot-sdk-cookie-policy input[type="tel"],
#ot-sdk-cookie-policy input[type="url"],
#ot-sdk-cookie-policy input[type="password"],
#ot-sdk-cookie-policy textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

#onetrust-banner-sdk textarea,
#onetrust-pc-sdk textarea,
#ot-sdk-cookie-policy textarea {
  min-height: 65px;
  padding-top: 6px;
  padding-bottom: 6px;
}

#onetrust-banner-sdk input[type="email"]:focus,
#onetrust-banner-sdk input[type="number"]:focus,
#onetrust-banner-sdk input[type="search"]:focus,
#onetrust-banner-sdk input[type="text"]:focus,
#onetrust-banner-sdk input[type="tel"]:focus,
#onetrust-banner-sdk input[type="url"]:focus,
#onetrust-banner-sdk input[type="password"]:focus,
#onetrust-banner-sdk textarea:focus,
#onetrust-banner-sdk select:focus,
#onetrust-pc-sdk input[type="email"]:focus,
#onetrust-pc-sdk input[type="number"]:focus,
#onetrust-pc-sdk input[type="search"]:focus,
#onetrust-pc-sdk input[type="text"]:focus,
#onetrust-pc-sdk input[type="tel"]:focus,
#onetrust-pc-sdk input[type="url"]:focus,
#onetrust-pc-sdk input[type="password"]:focus,
#onetrust-pc-sdk textarea:focus,
#onetrust-pc-sdk select:focus,
#ot-sdk-cookie-policy input[type="email"]:focus,
#ot-sdk-cookie-policy input[type="number"]:focus,
#ot-sdk-cookie-policy input[type="search"]:focus,
#ot-sdk-cookie-policy input[type="text"]:focus,
#ot-sdk-cookie-policy input[type="tel"]:focus,
#ot-sdk-cookie-policy input[type="url"]:focus,
#ot-sdk-cookie-policy input[type="password"]:focus,
#ot-sdk-cookie-policy textarea:focus,
#ot-sdk-cookie-policy select:focus {
  border: 1px solid #000;
  outline: 0;
}

#onetrust-banner-sdk label,
#onetrust-banner-sdk legend,
#onetrust-pc-sdk label,
#onetrust-pc-sdk legend,
#ot-sdk-cookie-policy label,
#ot-sdk-cookie-policy legend {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

#onetrust-banner-sdk fieldset,
#onetrust-pc-sdk fieldset,
#ot-sdk-cookie-policy fieldset {
  padding: 0;
  border-width: 0;
}

#onetrust-banner-sdk input[type="checkbox"],
#onetrust-banner-sdk input[type="radio"],
#onetrust-pc-sdk input[type="checkbox"],
#onetrust-pc-sdk input[type="radio"],
#ot-sdk-cookie-policy input[type="checkbox"],
#ot-sdk-cookie-policy input[type="radio"] {
  display: inline;
}

#onetrust-banner-sdk label > .label-body,
#onetrust-pc-sdk label > .label-body,
#ot-sdk-cookie-policy label > .label-body {
  display: inline-block;
  margin-left: 0.5rem;
  font-weight: normal;
}

#onetrust-banner-sdk ul,
#onetrust-pc-sdk ul,
#ot-sdk-cookie-policy ul {
  list-style: circle inside;
}

#onetrust-banner-sdk ol,
#onetrust-pc-sdk ol,
#ot-sdk-cookie-policy ol {
  list-style: decimal inside;
}

#onetrust-banner-sdk ol,
#onetrust-banner-sdk ul,
#onetrust-pc-sdk ol,
#onetrust-pc-sdk ul,
#ot-sdk-cookie-policy ol,
#ot-sdk-cookie-policy ul {
  padding-left: 0;
  margin-top: 0;
}

#onetrust-banner-sdk ul ul,
#onetrust-banner-sdk ul ol,
#onetrust-banner-sdk ol ol,
#onetrust-banner-sdk ol ul,
#onetrust-pc-sdk ul ul,
#onetrust-pc-sdk ul ol,
#onetrust-pc-sdk ol ol,
#onetrust-pc-sdk ol ul,
#ot-sdk-cookie-policy ul ul,
#ot-sdk-cookie-policy ul ol,
#ot-sdk-cookie-policy ol ol,
#ot-sdk-cookie-policy ol ul {
  margin: 1.5rem 0 1.5rem 3rem;
  font-size: 90%;
}

#onetrust-banner-sdk li,
#onetrust-pc-sdk li,
#ot-sdk-cookie-policy li {
  margin-bottom: 1rem;
}

#onetrust-banner-sdk code,
#onetrust-pc-sdk code,
#ot-sdk-cookie-policy code {
  padding: 0.2rem 0.5rem;
  margin: 0 0.2rem;
  font-size: 90%;
  white-space: nowrap;
  background: #f1f1f1;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
}

#onetrust-banner-sdk pre > code,
#onetrust-pc-sdk pre > code,
#ot-sdk-cookie-policy pre > code {
  display: block;
  padding: 1rem 1.5rem;
  white-space: pre;
}

#onetrust-banner-sdk th,
#onetrust-banner-sdk td,
#onetrust-pc-sdk th,
#onetrust-pc-sdk td,
#ot-sdk-cookie-policy th,
#ot-sdk-cookie-policy td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #e1e1e1;
}

#onetrust-banner-sdk .ot-sdk-u-full-width,
#onetrust-pc-sdk .ot-sdk-u-full-width,
#ot-sdk-cookie-policy .ot-sdk-u-full-width {
  width: 100%;
  box-sizing: border-box;
}

#onetrust-banner-sdk .ot-sdk-u-max-full-width,
#onetrust-pc-sdk .ot-sdk-u-max-full-width,
#ot-sdk-cookie-policy .ot-sdk-u-max-full-width {
  max-width: 100%;
  box-sizing: border-box;
}

#onetrust-banner-sdk .ot-sdk-u-pull-right,
#onetrust-pc-sdk .ot-sdk-u-pull-right,
#ot-sdk-cookie-policy .ot-sdk-u-pull-right {
  float: right;
}

#onetrust-banner-sdk .ot-sdk-u-pull-left,
#onetrust-pc-sdk .ot-sdk-u-pull-left,
#ot-sdk-cookie-policy .ot-sdk-u-pull-left {
  float: left;
}

#onetrust-banner-sdk hr,
#onetrust-pc-sdk hr,
#ot-sdk-cookie-policy hr {
  margin-top: 3rem;
  margin-bottom: 3.5rem;
  border-width: 0;
  border-top: 1px solid #e1e1e1;
}

#onetrust-banner-sdk .ot-sdk-container:after,
#onetrust-banner-sdk .ot-sdk-row:after,
#onetrust-banner-sdk .ot-sdk-u-cf,
#onetrust-pc-sdk .ot-sdk-container:after,
#onetrust-pc-sdk .ot-sdk-row:after,
#onetrust-pc-sdk .ot-sdk-u-cf,
#ot-sdk-cookie-policy .ot-sdk-container:after,
#ot-sdk-cookie-policy .ot-sdk-row:after,
#ot-sdk-cookie-policy .ot-sdk-u-cf {
  content: "";
  display: table;
  clear: both;
}

#onetrust-banner-sdk .ot-sdk-row,
#onetrust-pc-sdk .ot-sdk-row,
#ot-sdk-cookie-policy .ot-sdk-row {
  margin: 0;
  max-width: none;
  display: block;
}

#onetrust-banner-sdk {
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.2);
}

#onetrust-banner-sdk.otCenterRounded {
  z-index: 2147483645;
  position: fixed;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: #fff;
  width: 60%;
  max-width: 650px;
  border-radius: 2.5px;
  margin: 0 auto;
  font-size: 14px;
  max-height: 90%;
  overflow-x: hidden;
  overflow-y: auto;
}

#onetrust-banner-sdk.otRelFont {
  font-size: 0.875rem;
}

#onetrust-banner-sdk::-webkit-scrollbar {
  width: 11px;
}

#onetrust-banner-sdk::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #c1c1c1;
}

#onetrust-banner-sdk {
  scrollbar-arrow-color: #c1c1c1;
  scrollbar-darkshadow-color: #c1c1c1;
  scrollbar-face-color: #c1c1c1;
  scrollbar-shadow-color: #c1c1c1;
}

#onetrust-banner-sdk h3,
#onetrust-banner-sdk p {
  color: dimgray;
}

#onetrust-banner-sdk #onetrust-policy {
  margin-top: 20px;
}

#onetrust-banner-sdk #onetrust-policy-title {
  float: left;
  text-align: left;
  font-size: 1em;
  line-height: 1.4;
  margin-bottom: 0;
  padding: 0 0 10px 30px;
  width: calc(100% - 90px);
}

#onetrust-banner-sdk #onetrust-policy-text,
#onetrust-banner-sdk .ot-b-addl-desc,
#onetrust-banner-sdk .ot-gv-list-handler {
  clear: both;
  float: left;
  margin: 0 30px 10px 30px;
  font-size: 0.813em;
  line-height: 1.5;
}

#onetrust-banner-sdk #onetrust-policy-text *,
#onetrust-banner-sdk .ot-b-addl-desc *,
#onetrust-banner-sdk .ot-gv-list-handler * {
  line-height: inherit;
  font-size: inherit;
  margin: 0;
}

#onetrust-banner-sdk .ot-gv-list-handler {
  padding: 0;
  border: 0;
  height: auto;
  width: auto;
}

#onetrust-banner-sdk .ot-b-addl-desc {
  display: block;
}

#onetrust-banner-sdk #onetrust-button-group-parent {
  padding: 15px 30px;
  text-align: center;
}

#onetrust-banner-sdk
#onetrust-button-group-parent:not(.has-reject-all-button)
#onetrust-button-group {
  text-align: right;
}

#onetrust-banner-sdk #onetrust-button-group {
  text-align: center;
  display: inline-block;
  width: 100%;
}

#onetrust-banner-sdk #onetrust-reject-all-handler,
#onetrust-banner-sdk #onetrust-pc-btn-handler {
  margin-right: 1em;
}

#onetrust-banner-sdk #onetrust-pc-btn-handler {
  border: 1px solid #6cc04a;
  max-width: 45%;
}

#onetrust-banner-sdk .banner-actions-container {
  float: right;
  width: 50%;
}

#onetrust-banner-sdk #onetrust-pc-btn-handler.cookie-setting-link {
  background-color: #fff;
  border: none;
  color: #6cc04a;
  text-decoration: underline;
  padding-left: 0;
  padding-right: 0;
  padding-top: 7px;
  padding-bottom: 7px;
}

#onetrust-banner-sdk #onetrust-accept-btn-handler,
#onetrust-banner-sdk #onetrust-reject-all-handler,
#onetrust-banner-sdk #onetrust-pc-btn-handler {
  background-color: #6cc04a;
  color: #fff;
  border-color: #6cc04a;
  min-width: 135px;
  padding: 12px 10px;
  letter-spacing: 0.05em;
  line-height: 1.4;
  font-size: 0.813em;
  font-weight: 600;
  height: auto;
  white-space: normal;
  word-break: break-word;
  word-wrap: break-word;
}


#onetrust-banner-sdk .has-reject-all-button #onetrust-pc-btn-handler {
  float: left;
  max-width: calc(40% - 18px);
}

#onetrust-banner-sdk
.has-reject-all-button
#onetrust-pc-btn-handler.cookie-setting-link {
  text-align: left;
  margin-right: 0;
}

#onetrust-pc-btn-handler:active,
#onetrust-pc-btn-handler:focus {
  outline: none;
}

#onetrust-banner-sdk .has-reject-all-button .banner-actions-container {
  max-width: 60%;
  width: auto;
}

#onetrust-banner-sdk .ot-close-icon {
  width: 44px;
  height: 44px;
  background-size: 12px;
  margin: -18px -18px 0 0;
  border: none;
  display: inline-block;
  padding: 0;
}

#onetrust-banner-sdk #onetrust-close-btn-container {
  position: absolute;
  right: 24px;
  top: 24px;
}

#onetrust-banner-sdk .banner_logo {
  display: none;
}

#onetrust-banner-sdk #banner-options {
  float: left;
  padding: 0 30px;
  width: calc(100% - 90px);
}

#onetrust-banner-sdk .banner-option {
  margin-bottom: 10px;
}

#onetrust-banner-sdk .banner-option-input {
  cursor: pointer;
  width: auto;
  height: auto;
  border: none;
  padding: 0;
  padding-right: 3px;
  margin: 0 0 6px;
  font-size: 0.82em;
  line-height: 1.4;
}

#onetrust-banner-sdk .banner-option-input * {
  pointer-events: none;
  font-size: inherit;
  line-height: inherit;
}

#onetrust-banner-sdk
.banner-option-input[aria-expanded="true"]
.ot-arrow-container {
  transform: rotate(90deg);
}

#onetrust-banner-sdk
.banner-option-input[aria-expanded="true"]
~ .banner-option-details {
  height: auto;
  display: block;
}

#onetrust-banner-sdk .banner-option-header {
  cursor: pointer;
  display: inline-block;
}

#onetrust-banner-sdk .banner-option-header :first-child {
  color: dimgray;
  font-weight: bold;
  float: left;
}

#onetrust-banner-sdk .ot-arrow-container,
#onetrust-banner-sdk .banner-option-details {
  transition: all 300ms ease-in 0s;
  -webkit-transition: all 300ms ease-in 0s;
  -moz-transition: all 300ms ease-in 0s;
  -o-transition: all 300ms ease-in 0s;
}

#onetrust-banner-sdk .ot-arrow-container {
  display: inline-block;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid dimgray;
  margin-left: 10px;
  vertical-align: middle;
}

#onetrust-banner-sdk .banner-option-details {
  display: none;
  font-size: 0.83em;
  line-height: 1.5;
  height: 0px;
  padding: 10px 10px 5px 10px;
}

#onetrust-banner-sdk .banner-option-details * {
  font-size: inherit;
  line-height: inherit;
  color: dimgray;
}

#onetrust-banner-sdk .ot-dpd-container {
  float: left;
  margin: 0 30px 10px 30px;
}

#onetrust-banner-sdk .ot-dpd-title {
  font-weight: bold;
  padding-bottom: 10px;
}

#onetrust-banner-sdk .ot-dpd-title {
  font-size: 1em;
  line-height: 1.4;
}

#onetrust-banner-sdk .ot-dpd-desc {
  font-size: 0.813em;
  line-height: 1.5;
  margin-bottom: 0;
}

#onetrust-banner-sdk .ot-dpd-desc * {
  margin: 0;
}

#onetrust-banner-sdk .onetrust-vendors-list-handler {
  display: block;
  margin-left: 0px;
  margin-top: 5px;
  padding: 0;
  margin-bottom: 0;
  border: 0;
  line-height: normal;
  height: auto;
  width: auto;
}

#onetrust-banner-sdk :not(.ot-dpd-desc) > .ot-b-addl-desc {
  float: left;
  margin: 0 30px 10px 30px;
}

#onetrust-banner-sdk .ot-dpd-desc > .ot-b-addl-desc {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1em;
  line-height: 1.5;
  float: none;
}

#onetrust-banner-sdk #onetrust-policy-text a {
  font-weight: bold;
  margin-left: 5px;
}

#onetrust-banner-sdk.ot-close-btn-link #onetrust-close-btn-container {
  top: 15px;
  transform: none;
  right: 15px;
}

#onetrust-banner-sdk.ot-close-btn-link #onetrust-close-btn-container button {
  padding: 0;
  white-space: pre-wrap;
  border: none;
  height: auto;
  line-height: 1.5;
  text-decoration: underline;
  font-size: 0.75em;
}

#onetrust-banner-sdk.ot-close-btn-link.ot-wo-title #onetrust-group-container {
  margin-top: 20px;
}

@media only screen and (max-width: 425px) {
  #onetrust-banner-sdk #onetrust-accept-btn-handler,
  #onetrust-banner-sdk #onetrust-reject-all-handler,
  #onetrust-banner-sdk #onetrust-pc-btn-handler {
    width: 100%;
    margin-bottom: 10px;
  }

  #onetrust-banner-sdk #onetrust-pc-btn-handler,
  #onetrust-banner-sdk #onetrust-reject-all-handler {
    margin-right: 0;
  }

  #onetrust-banner-sdk
  .has-reject-all-button
  #onetrust-pc-btn-handler.cookie-setting-link {
    text-align: center;
  }

  #onetrust-banner-sdk .banner-actions-container,
  #onetrust-banner-sdk #onetrust-pc-btn-handler {
    width: 100%;
    max-width: none;
  }

  #onetrust-banner-sdk.otCenterRounded {
    width: 95%;
  }
}

@media only screen and (max-width: 600px) {
  #onetrust-banner-sdk .ot-sdk-container {
    width: auto;
    padding: 0;
  }

  #onetrust-banner-sdk #onetrust-policy-title {
    padding: 0 22px 10px 22px;
  }

  #onetrust-banner-sdk #onetrust-policy-text,
  #onetrust-banner-sdk :not(.ot-dpd-desc) > .ot-b-addl-desc,
  #onetrust-banner-sdk .ot-dpd-container {
    margin: 0 22px 10px 22px;
    width: calc(100% - 44px);
  }

  #onetrust-banner-sdk #onetrust-button-group-parent {
    padding: 15px 22px;
  }

  #onetrust-banner-sdk #banner-options {
    padding: 0 22px;
    width: calc(100% - 44px);
  }

  #onetrust-banner-sdk .banner-option {
    margin-bottom: 6px;
  }

  #onetrust-banner-sdk .has-reject-all-button #onetrust-pc-btn-handler {
    float: none;
    max-width: 100%;
  }

  #onetrust-banner-sdk .has-reject-all-button .banner-actions-container {
    width: 100%;
    text-align: center;
    max-width: 100%;
  }

  #onetrust-banner-sdk.ot-close-btn-link #onetrust-group-container {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 426px) and (max-width: 896px) {
  #onetrust-banner-sdk.otCenterRounded {
    max-width: 600px;
    width: 95%;
  }
}

#onetrust-consent-sdk #onetrust-banner-sdk {
  background-color: #ffffff;
}

#onetrust-consent-sdk #onetrust-policy-title,
#onetrust-consent-sdk #onetrust-policy-text,
#onetrust-consent-sdk .ot-b-addl-desc,
#onetrust-consent-sdk .ot-dpd-desc,
#onetrust-consent-sdk .ot-dpd-title,
#onetrust-consent-sdk
#onetrust-policy-text
*:not(.onetrust-vendors-list-handler),
#onetrust-consent-sdk .ot-dpd-desc *:not(.onetrust-vendors-list-handler),
#onetrust-consent-sdk #onetrust-banner-sdk #banner-options *,
#onetrust-banner-sdk .ot-cat-header {
  color: #00567a;
}

#onetrust-consent-sdk #onetrust-banner-sdk .banner-option-details {
  background-color: #e9e9e9;
}

#onetrust-consent-sdk #onetrust-banner-sdk a[href],
#onetrust-consent-sdk #onetrust-banner-sdk a[href] font,
#onetrust-consent-sdk #onetrust-banner-sdk .ot-link-btn {
  color: #00567a;
}

#onetrust-consent-sdk #onetrust-accept-btn-handler,
#onetrust-banner-sdk #onetrust-reject-all-handler {
  background-color: #00567a;
  border-color: #00567a;
  color: #ffffff;
}

#onetrust-consent-sdk #onetrust-pc-btn-handler,
#onetrust-consent-sdk #onetrust-pc-btn-handler.cookie-setting-link {
  color: #00a03b;
  border-color: #00a03b;
  background-color: #ffffff;
}

#onetrust-consent-sdk #onetrust-policy-text {
  font-size: 1em !important;
}

.onetrust-pc-dark-filter {
  background: rgba(255, 255, 255, 0.8) !important;
}

#onetrust-banner-sdk {
  box-shadow: 0 0 1.5625rem rgba(0, 0, 0, 0.15) !important;
}

#onetrust-consent-sdk #onetrust-banner-sdk {
  background-color: #fff !important;
}

#onetrust-banner-sdk.otCenterRounded {
  border-radius: 0 !important;
}

#onetrust-consent-sdk #onetrust-policy-text {
  font-size: 1rem !important;
  line-height: var(--line-height-base, 1.625) !important;
}

#onetrust-banner-sdk #onetrust-pc-btn-handler {
  font-size: 1rem !important;
  font-weight: normal !important;
  letter-spacing: 0 !important;
  -webkit-font-smoothing: antialiased !important;
}

#onetrust-consent-sdk #onetrust-accept-btn-handler,
#onetrust-banner-sdk #onetrust-reject-all-handler {
  border-radius: 999rem !important;
  padding: 0.5rem 1.25rem !important;
  font-size: 0.875rem !important;
}


#onetrust-consent-sdk #onetrust-accept-btn-handler {
  margin-right: 1em;
}

#onetrust-consent-sdk #onetrust-reject-all-handler {
  background: #FFFFFF;
  border: 2px solid #00567a;
  color: #00567a;
}

#onetrust-banner-sdk #onetrust-policy {
  margin-top: 60px !important;
}

#onetrust-banner-sdk #onetrust-policy-text,
#onetrust-banner-sdk .ot-b-addl-desc,
#onetrust-banner-sdk .ot-gv-list-handler {
  margin: 0 60px 10px 60px !important;
}

#onetrust-banner-sdk #onetrust-button-group-parent {
  padding: 20px 60px !important;
}

#onetrust-banner-sdk #onetrust-policy {
  margin-top: 30px !important;
}

#onetrust-banner-sdk #onetrust-policy-text {
  margin: 0 30px 10px 30px !important;
  width: calc(100% - 60px) !important;
}

#onetrust-banner-sdk #onetrust-button-group-parent {
  padding: 20px 30px !important;
}

#onetrust-banner-sdk #onetrust-accept-btn-handler,
#onetrust-banner-sdk #onetrust-reject-all-handler,
#onetrust-banner-sdk #onetrust-pc-btn-handler {
  // width: auto !important;
}


#onetrust-banner-sdk #onetrust-pc-btn-handler,
#onetrust-banner-sdk #onetrust-reject-all-handler {
  margin-right: 15px !important;
}

#onetrust-banner-sdk .banner-actions-container {
  display: contents;
}

#onetrust-pc-sdk {
  position: fixed;
  width: 730px;
  max-width: 730px;
  height: 610px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  font-size: 16px;
  z-index: 2147483647;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0), 0 7px 14px 0 rgba(50, 50, 93, 0.1);
}

#onetrust-pc-sdk.otRelFont {
  font-size: 1rem;
}

#onetrust-pc-sdk *,
#onetrust-pc-sdk ::after,
#onetrust-pc-sdk ::before {
  box-sizing: content-box;
}

#onetrust-pc-sdk #ot-addtl-venlst .ot-arw-cntr,
#onetrust-pc-sdk .ot-hide-tgl {
  visibility: hidden;
}

#onetrust-pc-sdk #ot-addtl-venlst .ot-arw-cntr *,
#onetrust-pc-sdk .ot-hide-tgl * {
  visibility: hidden;
}

#onetrust-pc-sdk #ot-pc-content,
#onetrust-pc-sdk #ot-pc-lst {
  height: calc(100% - 185px);
}

#onetrust-pc-sdk li {
  list-style: none;
}

#onetrust-pc-sdk ul,
#onetrust-pc-sdk li {
  margin: 0;
}

#onetrust-pc-sdk a {
  text-decoration: none;
}

#onetrust-pc-sdk .ot-link-btn {
  padding: 0;
  margin-bottom: 0;
  border: 0;
  font-weight: normal;
  line-height: normal;
  width: auto;
  height: auto;
}

#onetrust-pc-sdk .ot-grps-cntr *::-webkit-scrollbar,
#onetrust-pc-sdk .ot-pc-scrollbar::-webkit-scrollbar {
  width: 11px;
}

#onetrust-pc-sdk .ot-grps-cntr *::-webkit-scrollbar-thumb,
#onetrust-pc-sdk .ot-pc-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #c1c1c1;
}

#onetrust-pc-sdk .ot-grps-cntr *,
#onetrust-pc-sdk .ot-pc-scrollbar {
  scrollbar-arrow-color: #c1c1c1;
  scrollbar-darkshadow-color: #c1c1c1;
  scrollbar-face-color: #c1c1c1;
  scrollbar-shadow-color: #c1c1c1;
}

#onetrust-pc-sdk .ot-pc-header {
  height: auto;
  padding: 10px;
  display: table;
  vertical-align: middle;
  width: calc(100% - 20px);
  min-height: 52px;
  border-bottom: 1px solid #d8d8d8;
  position: relative;
}

#onetrust-pc-sdk .ot-pc-logo {
  display: table-cell;
  vertical-align: middle;
  width: 180px;
  height: 40px;
}

#onetrust-pc-sdk .ot-title-cntr {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: calc(100% - 190px);
  padding-left: 10px;
}

#onetrust-pc-sdk .ot-always-active {
  font-size: 0.813em;
  line-height: 1.5;
  font-weight: 700;
  color: #3860be;
}

#onetrust-pc-sdk .ot-close-cntr {
  float: right;
  position: absolute;
  right: -9px;
  top: 50%;
  transform: translateY(-50%);
}

#onetrust-pc-sdk #ot-pc-content {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

#onetrust-pc-sdk .ot-grps-cntr,
#onetrust-pc-sdk .ot-grps-cntr > * {
  overflow-y: auto;
}

#onetrust-pc-sdk .category-menu-switch-handler {
  cursor: pointer;
  border-left: 10px solid transparent;
  background-color: #f4f4f4;
  border-bottom: 1px solid #d7d7d7;
  padding-top: 12px;
  padding-right: 5px;
  padding-bottom: 12px;
  padding-left: 12px;
  overflow: hidden;
}

#onetrust-pc-sdk .category-menu-switch-handler h3 {
  float: left;
  text-align: left;
  margin: 0;
  color: dimgray;
  line-height: 1.4;
  font-size: 0.875em;
  word-break: break-word;
  word-wrap: break-word;
}

#onetrust-pc-sdk .ot-active-menu {
  border-left: 10px solid #68b631;
  background-color: #fff;
  border-bottom: none;
  position: relative;
}

#onetrust-pc-sdk .ot-active-menu h3 {
  color: #263238;
  font-weight: bold;
}

#onetrust-pc-sdk .ot-desc-cntr {
  word-break: break-word;
  word-wrap: break-word;
  padding-top: 20px;
  padding-right: 16px;
  padding-bottom: 15px;
}

#onetrust-pc-sdk .ot-grp-desc {
  word-break: break-word;
  word-wrap: break-word;
  text-align: left;
  font-size: 0.813em;
  line-height: 1.5;
  margin: 0;
}

#onetrust-pc-sdk .ot-grp-desc * {
  font-size: inherit;
  line-height: inherit;
}

#onetrust-pc-sdk #ot-pc-desc a {
  color: #3860be;
  cursor: pointer;
  font-size: 1em;
}

#onetrust-pc-sdk #ot-pc-desc a:hover {
  color: #1883fd;
}

#onetrust-pc-sdk #ot-pc-desc * {
  font-size: inherit;
}

#onetrust-pc-sdk #ot-pc-desc ul li {
  padding: 10px 0px;
  border-bottom: 1px solid #e2e2e2;
}

#onetrust-pc-sdk .ot-btn-subcntr {
  float: right;
}

#onetrust-pc-sdk .ot-close-icon {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjQ3Ljk3MSIgaGVpZ2h0PSI0Ny45NzEiIHZpZXdCb3g9IjAgMCA0Ny45NzEgNDcuOTcxIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0Ny45NzEgNDcuOTcxOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PHBhdGggZD0iTTI4LjIyOCwyMy45ODZMNDcuMDkyLDUuMTIyYzEuMTcyLTEuMTcxLDEuMTcyLTMuMDcxLDAtNC4yNDJjLTEuMTcyLTEuMTcyLTMuMDctMS4xNzItNC4yNDIsMEwyMy45ODYsMTkuNzQ0TDUuMTIxLDAuODhjLTEuMTcyLTEuMTcyLTMuMDctMS4xNzItNC4yNDIsMGMtMS4xNzIsMS4xNzEtMS4xNzIsMy4wNzEsMCw0LjI0MmwxOC44NjUsMTguODY0TDAuODc5LDQyLjg1Yy0xLjE3MiwxLjE3MS0xLjE3MiwzLjA3MSwwLDQuMjQyQzEuNDY1LDQ3LjY3NywyLjIzMyw0Ny45NywzLDQ3Ljk3czEuNTM1LTAuMjkzLDIuMTIxLTAuODc5bDE4Ljg2NS0xOC44NjRMNDIuODUsNDcuMDkxYzAuNTg2LDAuNTg2LDEuMzU0LDAuODc5LDIuMTIxLDAuODc5czEuNTM1LTAuMjkzLDIuMTIxLTAuODc5YzEuMTcyLTEuMTcxLDEuMTcyLTMuMDcxLDAtNC4yNDJMMjguMjI4LDIzLjk4NnoiLz48L2c+PC9zdmc+");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: center;
  height: 44px;
  width: 44px;
  display: inline-block;
}

#onetrust-pc-sdk .ot-tgl {
  float: right;
  position: relative;
  z-index: 1;
}

#onetrust-pc-sdk .ot-tgl input:checked + .ot-switch .ot-switch-nob {
  background-color: #cddcf2;
}

#onetrust-pc-sdk .ot-tgl input:checked + .ot-switch .ot-switch-nob:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
  background-color: #4285f4;
}

#onetrust-pc-sdk .ot-tgl input:focus + .ot-switch .ot-switch-nob:before {
  box-shadow: 0 0 1px #2196f3;
  outline: #000 auto 5px;
}

#onetrust-pc-sdk .ot-switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 10px;
  margin-bottom: 0;
}

#onetrust-pc-sdk .ot-switch-nob {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f2f1f1;
  border: none;
  transition: all 0.2s ease-in 0s;
  -moz-transition: all 0.2s ease-in 0s;
  -o-transition: all 0.2s ease-in 0s;
  -webkit-transition: all 0.2s ease-in 0s;
  border-radius: 46px;
}

#onetrust-pc-sdk .ot-switch-nob:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  bottom: 1px;
  background-color: #7d7d7d;
  -webkit-transition: 0.4s;
  border-radius: 100%;
  top: -5px;
  transition: 0.4s;
}

#onetrust-pc-sdk .ot-chkbox {
  z-index: 1;
  position: relative;
}

#onetrust-pc-sdk .ot-chkbox input:checked ~ label::before {
  background-color: #3860be;
}

#onetrust-pc-sdk .ot-chkbox input + label::after {
  content: none;
  color: #fff;
}

#onetrust-pc-sdk .ot-chkbox input:checked + label::after {
  content: "";
}

#onetrust-pc-sdk .ot-chkbox input:focus + label::before {
  outline-style: solid;
  outline-width: 2px;
  outline-style: auto;
}

#onetrust-pc-sdk .ot-chkbox label {
  position: relative;
  height: 20px;
  padding-left: 30px;
  display: inline-block;
  cursor: pointer;
}

#onetrust-pc-sdk .ot-chkbox label::before,
#onetrust-pc-sdk .ot-chkbox label::after {
  position: absolute;
  content: "";
  display: inline-block;
  border-radius: 3px;
}

#onetrust-pc-sdk .ot-chkbox label::before {
  height: 18px;
  width: 18px;
  border: 1px solid #3860be;
  left: 0px;
}

#onetrust-pc-sdk .ot-chkbox label::after {
  height: 5px;
  width: 9px;
  border-left: 3px solid;
  border-bottom: 3px solid;
  transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  left: 4px;
  top: 5px;
}

#onetrust-pc-sdk .ot-label-txt {
  display: none;
}

#onetrust-pc-sdk .ot-fltr-opt .ot-label-txt {
  display: block;
}

#onetrust-pc-sdk .ot-chkbox input,
#onetrust-pc-sdk .ot-tgl input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

#onetrust-pc-sdk .ot-arw-cntr {
  float: right;
  position: relative;
}

#onetrust-pc-sdk .ot-arw {
  width: 16px;
  height: 16px;
  margin-left: 5px;
  color: dimgray;
  display: inline-block;
  vertical-align: middle;
  -webkit-transition: all 300ms ease-in 0s;
  -moz-transition: all 300ms ease-in 0s;
  -o-transition: all 300ms ease-in 0s;
  transition: all 300ms ease-in 0s;
}

#onetrust-pc-sdk input:checked ~ .ot-acc-hdr .ot-arw {
  transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

#onetrust-pc-sdk .ot-label-status {
  font-size: 0.75em;
  position: relative;
  top: 2px;
  display: none;
  padding-right: 5px;
  float: left;
}

#onetrust-pc-sdk #ot-lst-cnt .ot-label-status {
  top: -6px;
}

#onetrust-pc-sdk .ot-fltr-btns {
  margin-left: 15px;
  overflow: hidden;
  margin-right: 15px;
}

#onetrust-pc-sdk .ot-fltr-btns button {
  padding: 12px 30px;
}

#onetrust-pc-sdk .ot-pc-footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  max-height: 160px;
  border-top: 1px solid #d8d8d8;
}

#onetrust-pc-sdk .ot-pc-footer button {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 0.813em;
  min-height: 40px;
  height: auto;
  line-height: normal;
  padding: 10px 30px;
}

#onetrust-pc-sdk .ot-tab-desc {
  margin-left: 3%;
}

#onetrust-pc-sdk .ot-grp-hdr1 {
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
}

#onetrust-pc-sdk .ot-desc-cntr h3 {
  color: #263238;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  font-weight: bold;
  font-size: 0.875em;
  line-height: 1.3;
  max-width: 70%;
}

#onetrust-pc-sdk #ot-pvcy-hdr {
  margin-bottom: 10px;
}

#onetrust-pc-sdk .ot-vlst-cntr {
  overflow: hidden;
}

#onetrust-pc-sdk .category-vendors-list-handler,
#onetrust-pc-sdk .category-host-list-handler,
#onetrust-pc-sdk .category-vendors-list-handler + a {
  display: block;
  float: left;
  color: #3860be;
  font-size: 0.813em;
  font-weight: 400;
  line-height: 1.1;
  cursor: pointer;
}

#onetrust-pc-sdk .category-vendors-list-handler:hover,
#onetrust-pc-sdk .category-host-list-handler:hover,
#onetrust-pc-sdk .category-vendors-list-handler + a:hover {
  color: #1883fd;
}

#onetrust-pc-sdk .category-vendors-list-handler + a::after {
  content: "";
  height: 15px;
  width: 15px;
  background-repeat: no-repeat;
  margin-left: 5px;
  float: right;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 511.626 511.627'%3E%3Cg fill='%231276CE'%3E%3Cpath d='M392.857 292.354h-18.274c-2.669 0-4.859.855-6.563 2.573-1.718 1.708-2.573 3.897-2.573 6.563v91.361c0 12.563-4.47 23.315-13.415 32.262-8.945 8.945-19.701 13.414-32.264 13.414H82.224c-12.562 0-23.317-4.469-32.264-13.414-8.945-8.946-13.417-19.698-13.417-32.262V155.31c0-12.562 4.471-23.313 13.417-32.259 8.947-8.947 19.702-13.418 32.264-13.418h200.994c2.669 0 4.859-.859 6.57-2.57 1.711-1.713 2.566-3.9 2.566-6.567V82.221c0-2.662-.855-4.853-2.566-6.563-1.711-1.713-3.901-2.568-6.57-2.568H82.224c-22.648 0-42.016 8.042-58.102 24.125C8.042 113.297 0 132.665 0 155.313v237.542c0 22.647 8.042 42.018 24.123 58.095 16.086 16.084 35.454 24.13 58.102 24.13h237.543c22.647 0 42.017-8.046 58.101-24.13 16.085-16.077 24.127-35.447 24.127-58.095v-91.358c0-2.669-.856-4.859-2.574-6.57-1.713-1.718-3.903-2.573-6.565-2.573z'/%3E%3Cpath d='M506.199 41.971c-3.617-3.617-7.905-5.424-12.85-5.424H347.171c-4.948 0-9.233 1.807-12.847 5.424-3.617 3.615-5.428 7.898-5.428 12.847s1.811 9.233 5.428 12.85l50.247 50.248-186.147 186.151c-1.906 1.903-2.856 4.093-2.856 6.563 0 2.479.953 4.668 2.856 6.571l32.548 32.544c1.903 1.903 4.093 2.852 6.567 2.852s4.665-.948 6.567-2.852l186.148-186.148 50.251 50.248c3.614 3.617 7.898 5.426 12.847 5.426s9.233-1.809 12.851-5.426c3.617-3.616 5.424-7.898 5.424-12.847V54.818c-.001-4.952-1.814-9.232-5.428-12.847z'/%3E%3C/g%3E%3C/svg%3E");
}

#onetrust-pc-sdk .category-host-list-handler,
#onetrust-pc-sdk .ot-vlst-cntr,
#onetrust-pc-sdk #ot-pc-desc + .category-vendors-list-handler {
  margin-top: 8px;
}

#onetrust-pc-sdk .ot-grp-hdr1 + .ot-vlst-cntr {
  margin-top: 0px;
  margin-bottom: 10px;
}

#onetrust-pc-sdk .ot-always-active-group h3.ot-cat-header,
#onetrust-pc-sdk .ot-subgrp.ot-always-active-group > h4 {
  max-width: 70%;
}

#onetrust-pc-sdk .ot-always-active-group .ot-tgl-cntr {
  max-width: 28%;
}

#onetrust-pc-sdk .ot-grp-desc ul,
#onetrust-pc-sdk li.ot-subgrp p ul {
  margin: 0px;
  margin-left: 15px;
  padding-bottom: 8px;
}

#onetrust-pc-sdk .ot-grp-desc ul li,
#onetrust-pc-sdk li.ot-subgrp p ul li {
  font-size: inherit;
  padding-top: 8px;
  display: list-item;
  list-style: disc;
}

#onetrust-pc-sdk ul.ot-subgrps {
  margin: 0;
  font-size: inherit;
}

#onetrust-pc-sdk ul.ot-subgrps li {
  padding: 0;
  border: none;
  position: relative;
}

#onetrust-pc-sdk ul.ot-subgrps li h4,
#onetrust-pc-sdk ul.ot-subgrps li p {
  font-size: 0.82em;
  line-height: 1.4;
}

#onetrust-pc-sdk ul.ot-subgrps li p {
  color: dimgray;
  clear: both;
  float: left;
  margin-top: 10px;
  margin-bottom: 0;
  word-break: break-word;
  word-wrap: break-word;
}

#onetrust-pc-sdk ul.ot-subgrps li h4 {
  color: #263238;
  font-weight: bold;
  margin-bottom: 0;
  float: left;
  position: relative;
  top: 3px;
}

#onetrust-pc-sdk li.ot-subgrp {
  margin-left: 30px;
  display: inline-block;
  width: calc(100% - 30px);
}

#onetrust-pc-sdk .ot-subgrp-tgl {
  float: right;
}

#onetrust-pc-sdk .ot-subgrp-tgl.ot-always-active-subgroup {
  width: auto;
}

#onetrust-pc-sdk .ot-pc-footer-logo {
  height: 30px;
  width: 100%;
  text-align: right;
  background: #f4f4f4;
  border-radius: 0 0 2px 2px;
}

#onetrust-pc-sdk .ot-pc-footer-logo a {
  display: inline-block;
  margin-top: 5px;
  margin-right: 10px;
}

#onetrust-pc-sdk #accept-recommended-btn-handler {
  float: right;
  text-align: center;
}

#onetrust-pc-sdk .save-preference-btn-handler {
  min-width: 155px;
  background-color: #68b631;
  border-radius: 2px;
  color: #fff;
  font-size: 0.9em;
  line-height: 1.1;
  text-align: center;
  margin-left: 15px;
  margin-right: 15px;
}

#onetrust-pc-sdk .ot-btn-subcntr button {
  // margin-right: 16px;
  margin: 15px;
}

#onetrust-pc-sdk.ot-ftr-stacked .save-preference-btn-handler,
#onetrust-pc-sdk.ot-ftr-stacked .ot-btn-subcntr {
  max-width: 40%;
  white-space: normal;
  text-align: center;
}

#onetrust-pc-sdk.ot-ftr-stacked .ot-btn-subcntr button {
  margin-left: auto;
  margin-right: auto;
  min-width: 60%;
  max-width: 90%;
}

#onetrust-pc-sdk.ot-ftr-stacked .ot-btn-subcntr button:nth-child(2) {
  margin-top: 0;
}

#onetrust-pc-sdk.ot-ftr-stacked #accept-recommended-btn-handler {
  float: none;
}

#onetrust-pc-sdk.ot-ftr-stacked .ot-btn-container {
  overflow: hidden;
}

#onetrust-pc-sdk #ot-pc-title {
  float: left;
  margin-left: 10px;
  max-width: 85%;
  overflow: hidden;
  position: relative;
  line-height: 1.2;
  max-height: 2.4em;
  padding-right: 1em;
  font-size: 1.37em;
}

#onetrust-pc-sdk #ot-pc-title:before {
  content: "...";
  right: 0px;
  bottom: 0px;
  position: absolute;
}

#onetrust-pc-sdk #ot-pc-title:after {
  position: absolute;
  content: "";
  width: 1em;
  height: 1em;
  right: 0px;
  background: #fff;
}

#onetrust-pc-sdk #ot-pc-lst {
  width: 100%;
  position: relative;
}

#onetrust-pc-sdk #ot-pc-lst .ot-acc-hdr {
  padding-top: 17px;
  padding-right: 15px;
  padding-bottom: 17px;
  padding-left: 20px;
  display: inline-block;
  width: calc(100% - 35px);
  vertical-align: middle;
}

#onetrust-pc-sdk #ot-pc-lst .ot-acc-txt {
  padding-top: 6px;
  padding-right: 15px;
  padding-bottom: 10px;
  padding-left: 20px;
}

#onetrust-pc-sdk .ot-lst-cntr {
  height: 100%;
}

#onetrust-pc-sdk #ot-pc-hdr {
  padding-top: 15px;
  padding-right: 30px;
  padding-bottom: 15px;
  padding-left: 20px;
  display: inline-block;
  width: calc(100% - 50px);
  height: 20px;
  border-bottom: 1px solid #d8d8d8;
}

#onetrust-pc-sdk #ot-pc-hdr input {
  height: 32px;
  width: 100%;
  border-radius: 50px;
  font-size: 0.8em;
  padding-right: 35px;
  padding-left: 15px;
  float: left;
}

#onetrust-pc-sdk #ot-pc-hdr input::placeholder {
  color: #d4d4d4;
  font-style: italic;
}

#onetrust-pc-sdk #ot-lst-cnt {
  height: calc(100% - 86px);
  padding-left: 30px;
  padding-right: 27px;
  padding-top: 20px;
  margin-top: 8px;
  margin-right: 3px;
  margin-bottom: 4px;
  margin-left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  transform: translate3d(0, 0, 0);
}

#onetrust-pc-sdk #ot-back-arw {
  height: 12px;
  width: 12px;
}

#onetrust-pc-sdk #ot-lst-title {
  display: inline-block;
  font-size: 1em;
}

#onetrust-pc-sdk #ot-lst-title span {
  color: dimgray;
  font-weight: bold;
  margin-left: 10px;
}

#onetrust-pc-sdk #ot-lst-title span * {
  font-size: inherit;
}

#onetrust-pc-sdk .ot-lst-subhdr {
  float: right;
  position: relative;
  bottom: 6px;
}

#onetrust-pc-sdk #ot-search-cntr {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 300px;
}

#onetrust-pc-sdk #ot-search-cntr svg {
  position: absolute;
  right: 0px;
  width: 30px;
  height: 30px;
  font-size: 1em;
  line-height: 1;
  top: 2px;
}

#onetrust-pc-sdk #ot-fltr-cntr {
  display: inline-block;
  position: relative;
  margin-left: 20px;
  vertical-align: middle;
  font-size: 0;
}

#onetrust-pc-sdk #filter-btn-handler {
  background-color: #3860be;
  border-radius: 17px;
  -moz-transition: 0.1s ease;
  -o-transition: 0.1s ease;
  -webkit-transition: 1s ease;
  transition: 0.1s ease;
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 0;
  position: relative;
}

#onetrust-pc-sdk #filter-btn-handler svg {
  cursor: pointer;
  width: 15px;
  height: 15px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-top: 5px;
}

#onetrust-pc-sdk #filter-btn-handler path {
  fill: #fff;
}

#onetrust-pc-sdk #ot-sel-blk {
  min-width: 200px;
  min-height: 30px;
  padding-left: 20px;
}

#onetrust-pc-sdk #ot-selall-vencntr,
#onetrust-pc-sdk #ot-selall-adtlvencntr {
  float: left;
  height: 100%;
}

#onetrust-pc-sdk #ot-selall-vencntr label,
#onetrust-pc-sdk #ot-selall-adtlvencntr label {
  height: 100%;
  padding-left: 0;
}

#onetrust-pc-sdk #ot-selall-hostcntr {
  width: 21px;
  height: 21px;
  position: relative;
  left: 20px;
}

#onetrust-pc-sdk #ot-selall-vencntr.line-through label::after,
#onetrust-pc-sdk #ot-selall-adtlvencntr.line-through label::after,
#onetrust-pc-sdk #ot-selall-licntr.line-through label::after,
#onetrust-pc-sdk #ot-selall-hostcntr.line-through label::after,
#onetrust-pc-sdk #ot-selall-gnvencntr.line-through label::after {
  height: auto;
  border-left: 0;
  left: 5px;
  top: 10.5px;
  transform: none;
  -o-transform: none;
  -ms-transform: none;
  -webkit-transform: none;
}

#onetrust-pc-sdk .ot-ven-name,
#onetrust-pc-sdk .ot-host-name {
  color: #2c3643;
  font-weight: bold;
  font-size: 0.813em;
  line-height: 1.2;
  margin: 0;
  height: auto;
  text-align: left;
  word-break: break-word;
  word-wrap: break-word;
}

#onetrust-pc-sdk .ot-ven-name *,
#onetrust-pc-sdk .ot-host-name * {
  font-size: inherit;
}

#onetrust-pc-sdk .ot-host-desc {
  font-size: 0.69em;
  line-height: 1.4;
  margin-top: 5px;
  margin-bottom: 5px;
  color: dimgray;
}

#onetrust-pc-sdk .ot-host-name > a {
  text-decoration: underline;
  position: relative;
  z-index: 2;
  margin-bottom: 5px;
  font-weight: bold;
}

#onetrust-pc-sdk .ot-host-hdr {
  float: left;
  width: calc(100% - 50px);
}

#onetrust-pc-sdk .ot-host-hdr .ot-host-name ~ a {
  margin-top: 5px;
  font-size: 0.813em;
  text-decoration: underline;
}

#onetrust-pc-sdk .ot-ven-hdr {
  width: 88%;
  float: right;
}

#onetrust-pc-sdk input:focus + .ot-acc-hdr {
  outline: #000 solid 1px !important;
}

#onetrust-pc-sdk #ot-selall-hostcntr input[type="checkbox"],
#onetrust-pc-sdk #ot-selall-vencntr input[type="checkbox"],
#onetrust-pc-sdk #ot-selall-adtlvencntr input[type="checkbox"] {
  position: absolute;
}

#onetrust-pc-sdk .ot-host-item .ot-chkbox {
  float: left;
}

#onetrust-pc-sdk.ot-addtl-vendors
#ot-lst-cnt:not(.ot-host-cnt)
.ot-sel-all-hdr {
  right: 38px;
}

#onetrust-pc-sdk.ot-addtl-vendors #ot-lst-cnt:not(.ot-host-cnt) #ot-sel-blk {
  background-color: #f9f9fc;
  border: 1px solid #e2e2e2;
  width: auto;
  padding-bottom: 5px;
  padding-top: 5px;
}

#onetrust-pc-sdk.ot-addtl-vendors
#ot-lst-cnt:not(.ot-host-cnt)
.ot-sel-all-chkbox {
  right: 2px;
  width: auto;
}

#onetrust-pc-sdk #ot-pc-lst .ot-acc-cntr {
  position: relative;
  border-left: 1px solid #e2e2e2;
  border-right: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
}

#onetrust-pc-sdk #ot-pc-lst .ot-acc-cntr input {
  z-index: 1;
}

#onetrust-pc-sdk #ot-pc-lst .ot-acc-cntr > .ot-acc-hdr {
  background: #f9f9fc;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #f9f9fc;
}

#onetrust-pc-sdk #ot-pc-lst .ot-acc-cntr > .ot-acc-hdr input {
  z-index: 2;
}

#onetrust-pc-sdk
#ot-pc-lst
.ot-acc-cntr
> input[type="checkbox"]:checked
~ .ot-acc-hdr {
  border-bottom: 1px solid #e2e2e2;
}

#onetrust-pc-sdk #ot-pc-lst .ot-acc-cntr .ot-addtl-venbox {
  display: none;
}

#onetrust-pc-sdk #ot-addtl-venlst .ot-tgl-cntr {
  margin-right: 13px;
}

#onetrust-pc-sdk .ot-vensec-title {
  font-size: 0.813em;
  display: inline-block;
}

#onetrust-pc-sdk .ot-ven-item > button:focus,
#onetrust-pc-sdk .ot-host-item > button:focus,
#onetrust-pc-sdk .ot-acc-cntr > button:focus {
  outline: #000 solid 2px;
}

#onetrust-pc-sdk .ot-ven-item > button,
#onetrust-pc-sdk .ot-host-item > button,
#onetrust-pc-sdk .ot-acc-cntr > button {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border: 0;
  opacity: 0;
  margin: 0;
  top: 0;
  left: 0;
}

#onetrust-pc-sdk .ot-ven-item > button ~ .ot-acc-hdr,
#onetrust-pc-sdk .ot-host-item > button ~ .ot-acc-hdr,
#onetrust-pc-sdk .ot-acc-cntr > button ~ .ot-acc-hdr {
  cursor: pointer;
}

#onetrust-pc-sdk .ot-ven-item > button[aria-expanded="false"] ~ .ot-acc-txt,
#onetrust-pc-sdk .ot-host-item > button[aria-expanded="false"] ~ .ot-acc-txt,
#onetrust-pc-sdk .ot-acc-cntr > button[aria-expanded="false"] ~ .ot-acc-txt {
  margin-top: 0;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  width: 100%;
  transition: 0.25s ease-out;
  display: none;
}

#onetrust-pc-sdk .ot-ven-item > button[aria-expanded="true"] ~ .ot-acc-txt,
#onetrust-pc-sdk .ot-host-item > button[aria-expanded="true"] ~ .ot-acc-txt,
#onetrust-pc-sdk .ot-acc-cntr > button[aria-expanded="true"] ~ .ot-acc-txt {
  transition: 0.1s ease-in;
  display: block;
}

#onetrust-pc-sdk .ot-hide-acc > button {
  pointer-events: none;
}

#onetrust-pc-sdk .ot-hide-acc .ot-arw-cntr > * {
  visibility: hidden;
}

#onetrust-pc-sdk #ot-ven-lst,
#onetrust-pc-sdk #ot-host-lst,
#onetrust-pc-sdk #ot-addtl-venlst,
#onetrust-pc-sdk #ot-gn-venlst {
  width: 100%;
}

#onetrust-pc-sdk #ot-ven-lst li,
#onetrust-pc-sdk #ot-host-lst li,
#onetrust-pc-sdk #ot-addtl-venlst li,
#onetrust-pc-sdk #ot-gn-venlst li {
  border: 1px solid #d7d7d7;
  border-radius: 2px;
  position: relative;
  margin-top: 10px;
}

#onetrust-pc-sdk #ot-ven-lst .ot-tgl-cntr {
  width: 65%;
}

#onetrust-pc-sdk #ot-host-lst .ot-tgl-cntr {
  width: 65%;
  float: left;
}

#onetrust-pc-sdk label {
  margin-bottom: 0;
}

#onetrust-pc-sdk .ot-host-notice {
  float: right;
}

#onetrust-pc-sdk .ot-ven-link,
#onetrust-pc-sdk .ot-host-expand {
  color: dimgray;
  font-size: 0.75em;
  line-height: 0.9;
  display: inline-block;
}

#onetrust-pc-sdk .ot-ven-link *,
#onetrust-pc-sdk .ot-host-expand * {
  font-size: inherit;
}

#onetrust-pc-sdk .ot-ven-link {
  position: relative;
  z-index: 2;
}

#onetrust-pc-sdk .ot-ven-link:hover {
  text-decoration: underline;
}

#onetrust-pc-sdk .ot-ven-dets {
  border-radius: 2px;
  background-color: #f8f8f8;
}

#onetrust-pc-sdk .ot-ven-dets div:first-child p:first-child {
  border-top: none;
}

#onetrust-pc-sdk .ot-ven-dets .ot-ven-disc:not(:first-child) {
  border-top: 1px solid #e9e9e9;
}

#onetrust-pc-sdk .ot-ven-dets .ot-ven-disc:nth-child(n + 3) p {
  display: inline-block;
}

#onetrust-pc-sdk .ot-ven-dets .ot-ven-disc:nth-child(n + 3) p:nth-of-type(odd) {
  width: 30%;
}

#onetrust-pc-sdk
.ot-ven-dets
.ot-ven-disc:nth-child(n + 3)
p:nth-of-type(even) {
  width: 50%;
  word-break: break-word;
  word-wrap: break-word;
}

#onetrust-pc-sdk .ot-ven-dets .ot-ven-disc p {
  padding-top: 5px;
  padding-bottom: 5px;
  display: block;
}

#onetrust-pc-sdk .ot-ven-dets p {
  font-size: 0.69em;
  text-align: left;
  vertical-align: middle;
  word-break: break-word;
  word-wrap: break-word;
  margin: 0;
  padding-bottom: 10px;
  padding-left: 15px;
  color: #2e3644;
}

#onetrust-pc-sdk .ot-ven-dets p:first-child {
  padding-top: 5px;
}

#onetrust-pc-sdk .ot-ven-dets .ot-ven-pur p:first-child {
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 5px;
  margin-bottom: 5px;
  font-weight: bold;
}

#onetrust-pc-sdk .ot-host-opt {
  display: inline-block;
  width: 100%;
  margin: 0;
  font-size: inherit;
}

#onetrust-pc-sdk .ot-host-opt li > div div {
  font-size: 0.81em;
  padding: 5px 0;
}

#onetrust-pc-sdk .ot-host-opt li > div div:nth-child(1) {
  width: 30%;
  float: left;
}

#onetrust-pc-sdk .ot-host-opt li > div div:nth-child(2) {
  width: 70%;
  float: left;
  word-break: break-word;
  word-wrap: break-word;
}

#onetrust-pc-sdk #ot-host-lst li.ot-host-info {
  border: none;
  font-size: 0.8em;
  color: dimgray;
  float: left;
  text-align: left;
  padding: 10px;
  margin-bottom: 10px;
  width: calc(100% - 10px);
  background-color: #f8f8f8;
}

#onetrust-pc-sdk #ot-host-lst li.ot-host-info a {
  color: dimgray;
}

#onetrust-pc-sdk #ot-host-lst li.ot-host-info > div {
  overflow: auto;
}

#onetrust-pc-sdk #no-results {
  text-align: center;
  margin-top: 30px;
}

#onetrust-pc-sdk #no-results p {
  font-size: 1em;
  color: #2e3644;
  word-break: break-word;
  word-wrap: break-word;
}

#onetrust-pc-sdk #no-results p span {
  font-weight: bold;
}

#onetrust-pc-sdk .ot-tgl-cntr {
  display: inline-block;
  vertical-align: middle;
}

#onetrust-pc-sdk .ot-arw-cntr,
#onetrust-pc-sdk .ot-tgl-cntr {
  float: right;
}

#onetrust-pc-sdk .ot-desc-cntr {
  padding-top: 0px;
  margin-top: 20px;
  padding-right: 0px;
  border-radius: 3px;
  overflow: hidden;
  padding-bottom: 10px;
}

#onetrust-pc-sdk .ot-leg-border-color {
  border: 1px solid #e9e9e9;
}

#onetrust-pc-sdk .ot-leg-border-color .ot-subgrp-cntr {
  border-top: 1px solid #e9e9e9;
  padding-bottom: 10px;
}

#onetrust-pc-sdk .ot-category-desc {
  padding: 0 20px 10px 10px !important;
}

#onetrust-pc-sdk .ot-grp-hdr1 {
  padding-left: 10px;
  width: calc(100% - 20px);
  padding-top: 10px;
  margin-bottom: 0px;
  padding-bottom: 8px;
}

#onetrust-pc-sdk .ot-subgrp-cntr {
  padding-top: 10px;
}

#onetrust-pc-sdk .ot-desc-cntr > *:not(.ot-grp-hdr1) {
  padding-left: 10px;
  padding-right: 10px;
}

#onetrust-pc-sdk .ot-pli-hdr {
  overflow: hidden;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  background-color: #f8f8f8;
  border: none;
  border-bottom: 1px solid #e9e9e9;
}

#onetrust-pc-sdk .ot-pli-hdr span:first-child {
  text-align: left;
  max-width: 80px;
  padding-right: 5px;
}

#onetrust-pc-sdk .ot-pli-hdr span:last-child {
  padding-right: 20px;
  text-align: center;
}

#onetrust-pc-sdk .ot-li-title {
  float: right;
  font-size: 0.813em;
}

#onetrust-pc-sdk .ot-desc-cntr .ot-tgl-cntr:first-of-type,
#onetrust-pc-sdk .ot-cat-header + .ot-tgl {
  padding-left: 55px;
  padding-right: 7px;
}

#onetrust-pc-sdk
.ot-always-active-group
.ot-grp-hdr1
.ot-tgl-cntr:first-of-type {
  padding-left: 0px;
}

#onetrust-pc-sdk .ot-cat-header,
#onetrust-pc-sdk .ot-subgrp h4 {
  max-width: calc(100% - 133px);
}

#onetrust-pc-sdk #ot-lst-cnt #ot-sel-blk {
  width: 100%;
  display: inline-block;
  padding: 0;
}

#onetrust-pc-sdk .ot-sel-all {
  display: inline-block;
  width: 100%;
}

#onetrust-pc-sdk .ot-sel-all-hdr,
#onetrust-pc-sdk .ot-sel-all-chkbox {
  width: 100%;
  float: right;
  position: relative;
}

#onetrust-pc-sdk .ot-sel-all-chkbox {
  z-index: 1;
}

#onetrust-pc-sdk :not(.ot-hosts-ui) .ot-sel-all-hdr,
#onetrust-pc-sdk :not(.ot-hosts-ui) .ot-sel-all-chkbox {
  right: 23px;
  width: calc(100% - 23px);
}

#onetrust-pc-sdk .ot-consent-hdr,
#onetrust-pc-sdk .ot-li-hdr {
  float: right;
  font-size: 0.813em;
  position: relative;
  line-height: normal;
  text-align: center;
  word-break: break-word;
  word-wrap: break-word;
}

#onetrust-pc-sdk .ot-hosts-ui .ot-consent-hdr {
  float: left;
  position: relative;
  left: 5px;
}

#onetrust-pc-sdk .ot-li-hdr {
  max-width: 100px;
  margin-right: 10px;
}

#onetrust-pc-sdk .ot-consent-hdr {
  max-width: 55px;
}

#onetrust-pc-sdk .ot-ven-ctgl {
  margin-left: 10px;
}

#onetrust-pc-sdk .ot-ven-litgl {
  margin-right: 55px;
}

#onetrust-pc-sdk .ot-ven-litgl.ot-ven-litgl-only {
  margin-right: 86px;
}

#onetrust-pc-sdk .ot-ven-ctgl,
#onetrust-pc-sdk .ot-ven-litgl,
#onetrust-pc-sdk .ot-ven-gvctgl {
  float: left;
}

#onetrust-pc-sdk .ot-ven-ctgl label,
#onetrust-pc-sdk .ot-ven-litgl label,
#onetrust-pc-sdk .ot-ven-gvctgl label {
  width: 22px;
  padding: 0;
}

#onetrust-pc-sdk #ot-selall-licntr {
  display: block;
  width: 21px;
  height: 21px;
  position: relative;
  float: right;
  right: 80px;
}

#onetrust-pc-sdk #ot-selall-licntr input {
  position: absolute;
}

#onetrust-pc-sdk #ot-selall-vencntr,
#onetrust-pc-sdk #ot-selall-adtlvencntr,
#onetrust-pc-sdk #ot-selall-gnvencntr {
  float: right;
  width: 21px;
  height: 21px;
  position: relative;
  right: 15px;
}

#onetrust-pc-sdk #ot-ven-lst .ot-tgl-cntr {
  float: right;
  width: auto;
}

#onetrust-pc-sdk .ot-ven-hdr {
  float: left;
  width: 60%;
}

#onetrust-pc-sdk #vdr-lst-dsc {
  font-size: 0.812em;
  line-height: 1.5;
  padding: 10px 15px 5px 15px;
}

#onetrust-pc-sdk #ot-anchor {
  border: 12px solid transparent;
  display: none;
  position: absolute;
  z-index: 2147483647;
  top: 40px;
  right: 35px;
  transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  background-color: #fff;
  -webkit-box-shadow: -3px -3px 5px -2px #c7c5c7;
  -moz-box-shadow: -3px -3px 5px -2px #c7c5c7;
  box-shadow: -3px -3px 5px -2px #c7c5c7;
}

#onetrust-pc-sdk #ot-fltr-modal {
  width: 300px;
  position: absolute;
  z-index: 2147483646;
  top: 46px;
  height: 90%;
  max-height: 350px;
  display: none;
  -moz-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  -webkit-transition: 2s ease;
  transition: 0.2s ease;
  opacity: 1;
  right: 0;
}

#onetrust-pc-sdk #ot-fltr-modal button {
  max-width: 200px;
  line-height: 1;
  word-break: break-word;
  white-space: normal;
  height: auto;
  font-weight: bold;
}

#onetrust-pc-sdk #ot-fltr-cnt {
  background-color: #fff;
  margin: 5px;
  border-radius: 3px;
  height: 100%;
  margin-right: 10px;
  padding-right: 10px;
  -webkit-box-shadow: 0px 0px 12px 2px #c7c5c7;
  -moz-box-shadow: 0px 0px 12px 2px #c7c5c7;
  box-shadow: 0px 0px 12px 2px #c7c5c7;
}

#onetrust-pc-sdk .ot-fltr-scrlcnt {
  overflow-y: auto;
  overflow-x: hidden;
  clear: both;
  max-height: calc(100% - 60px);
}

#onetrust-pc-sdk .ot-fltr-opt {
  margin-bottom: 25px;
  margin-left: 15px;
  clear: both;
}

#onetrust-pc-sdk .ot-fltr-opt label {
  height: auto;
}

#onetrust-pc-sdk .ot-fltr-opt span {
  cursor: pointer;
  color: dimgray;
  font-size: 0.8em;
  line-height: 1.1;
  font-weight: normal;
}

#onetrust-pc-sdk #clear-filters-handler {
  float: right;
  margin-top: 15px;
  margin-bottom: 10px;
  text-decoration: none;
  color: #3860be;
  font-size: 0.9em;
  border: none;
  padding: 1px;
}

#onetrust-pc-sdk #clear-filters-handler:hover {
  color: #1883fd;
}

#onetrust-pc-sdk #clear-filters-handler:focus {
  outline: #000 solid 1px;
}

#onetrust-pc-sdk #filter-apply-handler {
  margin-right: 10px;
}

#onetrust-pc-sdk .ot-grp-desc + .ot-leg-btn-container {
  margin-top: 0;
}

#onetrust-pc-sdk .ot-leg-btn-container {
  display: inline-block;
  width: 100%;
  margin-top: 10px;
}

#onetrust-pc-sdk .ot-leg-btn-container button {
  height: auto;
  padding: 6.5px 8px;
  margin-bottom: 0;
  line-height: normal;
  letter-spacing: 0;
}

#onetrust-pc-sdk .ot-leg-btn-container svg {
  display: none;
  height: 14px;
  width: 14px;
  padding-right: 5px;
  vertical-align: sub;
}

#onetrust-pc-sdk .ot-active-leg-btn {
  cursor: default;
  pointer-events: none;
}

#onetrust-pc-sdk .ot-active-leg-btn svg {
  display: inline-block;
}

#onetrust-pc-sdk .ot-remove-objection-handler {
  border: none;
  text-decoration: underline;
  padding: 0;
  font-size: 0.82em;
  font-weight: 600;
  line-height: 1.4;
  padding-left: 10px;
}

#onetrust-pc-sdk .ot-obj-leg-btn-handler span {
  font-weight: bold;
  text-align: center;
  font-size: 0.91em;
  line-height: 1.5;
}

#onetrust-pc-sdk.ot-close-btn-link #close-pc-btn-handler {
  border: none;
  height: auto;
  line-height: 1.5;
  text-decoration: underline;
  font-size: 0.69em;
  background: none;
  width: auto;
}

#onetrust-pc-sdk.ot-close-btn-link .ot-close-cntr {
  right: 5px;
  top: auto;
  transform: none;
}

#onetrust-pc-sdk.otPcTab[dir="rtl"] input ~ .ot-acc-hdr .ot-arw,
#onetrust-pc-sdk.otPcTab[dir="rtl"] #ot-back-arw {
  transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

#onetrust-pc-sdk.otPcTab[dir="rtl"] input:checked ~ .ot-acc-hdr .ot-arw {
  transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
}

#onetrust-pc-sdk.otPcTab[dir="rtl"] #ot-search-cntr svg {
  right: 15px;
}

#onetrust-pc-sdk.otPcTab[dir="rtl"] .ot-chkbox label::after {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  border-left: 0;
  border-right: 3px solid;
}

#onetrust-pc-sdk #close-pc-btn-handler.ot-close-icon {
  padding: 0;
  background-color: transparent;
  border: none;
  margin: 0;
}

@media (max-width: 767px) {
  #onetrust-pc-sdk {
    width: 100%;
    border: none;
  }

  #onetrust-pc-sdk .ot-sdk-container,
  #onetrust-pc-sdk .ot-sdk-container {
    padding: 0;
    margin: 0;
  }

  #onetrust-pc-sdk #ot-pc-title {
    margin-left: 10px;
    max-width: 60%;
  }

  #onetrust-pc-sdk .ot-desc-cntr {
    margin: 0;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 15px;
    padding-left: 20px;
    position: relative;
    left: auto;
  }

  #onetrust-pc-sdk .ot-desc-cntr {
    margin-top: 20px;
    margin-left: 20px;
    padding: 0;
    padding-bottom: 10px;
  }

  #onetrust-pc-sdk .ot-grps-cntr {
    max-height: none;
    overflow: hidden;
  }

  #onetrust-pc-sdk #accept-recommended-btn-handler {
    float: none;
  }
}

@media (min-width: 768px) {
  #onetrust-pc-sdk.ot-tgl-with-label .ot-label-status {
    display: inline;
  }

  #onetrust-pc-sdk.ot-tgl-with-label #ot-pc-lst .ot-label-status {
    display: none;
  }

  #onetrust-pc-sdk.ot-tgl-with-label.ot-leg-opt-out .ot-pli-hdr {
    padding-right: 8%;
  }

  #onetrust-pc-sdk.ot-tgl-with-label .ot-cat-header {
    max-width: 60%;
  }

  #onetrust-pc-sdk.ot-tgl-with-label .ot-subgrp h4 {
    max-width: 58%;
  }

  #onetrust-pc-sdk.ot-tgl-with-label .ot-desc-cntr .ot-tgl-cntr:first-of-type,
  #onetrust-pc-sdk.ot-tgl-with-label .ot-cat-header + .ot-tgl {
    padding-left: 15px;
  }
}

@media (max-width: 640px) {
  #onetrust-pc-sdk {
    height: 100%;
  }

  #onetrust-pc-sdk .ot-pc-header {
    padding: 10px;
    width: calc(100% - 20px);
  }

  #onetrust-pc-sdk #ot-pc-content {
    overflow: auto;
  }

  #onetrust-pc-sdk .ot-sdk-row .ot-sdk-columns {
    width: 100%;
  }

  #onetrust-pc-sdk .ot-desc-cntr {
    margin: 0;
    overflow: hidden;
  }

  #onetrust-pc-sdk .ot-desc-cntr {
    margin-left: 10px;
    width: calc(100% - 15px);
    margin-top: 5px;
    margin-bottom: 5px;
  }

  #onetrust-pc-sdk .ot-ven-hdr {
    max-width: 80%;
  }

  #onetrust-pc-sdk #ot-lst-cnt {
    width: calc(100% - 18px);
    padding-top: 13px;
    padding-right: 5px;
    padding-left: 10px;
  }

  #onetrust-pc-sdk .ot-grps-cntr {
    width: 100%;
  }

  #onetrust-pc-sdk .ot-pc-footer {
    max-height: 300px;
  }

  #onetrust-pc-sdk #ot-pc-content,
  #onetrust-pc-sdk #ot-pc-lst {
    height: calc(100% - 322px);
  }

  #onetrust-pc-sdk.ot-close-btn-link #close-pc-btn-handler {
    position: fixed;
    top: 10px;
    right: 15px;
  }

  #onetrust-pc-sdk.ot-close-btn-link .ot-pc-header {
    padding-top: 25px;
  }

  #onetrust-pc-sdk.ot-close-btn-link #ot-pc-title {
    max-width: 100%;
  }
}

@media (max-width: 640px) and (orientation: portrait) {
  #onetrust-pc-sdk #ot-pc-hdr {
    height: 70px;
    padding: 15px 0;
    width: 100%;
  }

  #onetrust-pc-sdk .ot-lst-subhdr {
    width: calc(100% - 15px);
    float: none;
    bottom: auto;
    display: inline-block;
    padding-top: 8px;
    padding-left: 15px;
  }

  #onetrust-pc-sdk .ot-btn-subcntr {
    float: none;
  }

  #onetrust-pc-sdk #ot-search-cntr {
    display: inline-block;
    width: calc(100% - 55px);
    position: relative;
  }

  #onetrust-pc-sdk #ot-anchor {
    top: 75px;
    right: 30px;
  }

  #onetrust-pc-sdk #ot-fltr-modal {
    top: 81px;
  }

  #onetrust-pc-sdk #ot-fltr-cntr {
    float: right;
    right: 15px;
  }

  #onetrust-pc-sdk #ot-lst-title {
    padding-left: 15px;
  }

  #onetrust-pc-sdk .ot-lst-cntr {
    overflow-y: scroll;
  }

  #onetrust-pc-sdk #ot-lst-cnt {
    height: auto;
    overflow: hidden;
  }

  #onetrust-pc-sdk .save-preference-btn-handler,
  #onetrust-pc-sdk #accept-recommended-btn-handler,
  #onetrust-pc-sdk .ot-pc-refuse-all-handler {
    width: calc(100% - 33px);
  }

  #onetrust-pc-sdk.ot-ftr-stacked .save-preference-btn-handler,
  #onetrust-pc-sdk.ot-ftr-stacked .ot-btn-subcntr {
    max-width: none;
  }

  #onetrust-pc-sdk.ot-ftr-stacked .ot-pc-footer button {
    margin: 15px;
  }

  #onetrust-pc-sdk.ot-ftr-stacked .ot-btn-subcntr button {
    min-width: none;
    max-width: none;
  }

  #onetrust-pc-sdk.ot-ftr-stacked .ot-btn-subcntr button:nth-child(2) {
    margin-top: 15px;
  }

  #onetrust-pc-sdk.ot-ftr-stacked .ot-btn-container button:not(:last-child) {
    margin-bottom: 0;
  }
}

@media (max-width: 425px) {
  #onetrust-pc-sdk #ot-pc-lst .ot-acc-txt {
    padding-top: 6px;
    padding-bottom: 10px;
  }

  #onetrust-pc-sdk #ot-pc-lst .ot-host-notice {
    float: left;
    margin-left: 30px;
  }

  #onetrust-pc-sdk #ot-pc-lst .ot-arw-cntr {
    float: none;
    display: inline;
  }

  #onetrust-pc-sdk #ot-pc-lst .ot-ven-hdr {
    float: left;
    width: 100%;
    max-width: 85%;
  }

  #onetrust-pc-sdk.ot-addtl-vendors
  #ot-pc-lst
  .ot-acc-cntr
  .ot-arw-cntr:first-of-type {
    float: right;
  }

  #onetrust-pc-sdk #ot-pc-title {
    max-width: 100%;
  }

  #onetrust-pc-sdk .ot-subgrp-cntr li.ot-subgrp {
    margin-left: 10px;
    width: calc(100% - 10px);
  }

  #onetrust-pc-sdk #ot-ven-lst .ot-tgl-cntr {
    width: auto;
    float: right;
  }

  #onetrust-pc-sdk #ot-ven-lst .ot-arw-cntr {
    float: right;
  }

  #onetrust-pc-sdk .ot-ven-hdr {
    max-width: 47%;
  }

  #onetrust-pc-sdk .ot-always-active-group .ot-tgl-cntr:first-of-type {
    max-width: none;
    padding-left: 20px;
  }
}

@media only screen and (max-height: 425px) and (max-width: 896px) and (orientation: landscape) {
  #onetrust-pc-sdk {
    height: 100%;
    width: 100%;
    max-width: none;
  }

  #onetrust-pc-sdk .ot-always-active-group .ot-tgl-cntr {
    max-width: none;
  }

  #onetrust-pc-sdk .ot-pc-header {
    padding: 10px;
    width: calc(100% - 20px);
  }

  #onetrust-pc-sdk .ot-lst-cntr {
    overflow-y: scroll;
  }

  #onetrust-pc-sdk #ot-lst-cnt {
    height: auto;
    overflow: hidden;
  }

  #onetrust-pc-sdk #accept-recommended-btn-handler {
    float: right;
  }

  #onetrust-pc-sdk .save-preference-btn-handler,
  #onetrust-pc-sdk #accept-recommended-btn-handler,
  #onetrust-pc-sdk .ot-pc-refuse-all-handler {
    width: auto;
  }

  #onetrust-pc-sdk #ot-pc-content,
  #onetrust-pc-sdk #ot-pc-lst {
    height: calc(100% - 155px);
  }

  #onetrust-pc-sdk .ot-pc-footer-logo {
    display: none;
  }

  #onetrust-pc-sdk.ot-shw-fltr .ot-lst-cntr {
    overflow: hidden;
  }

  #onetrust-pc-sdk.ot-shw-fltr #ot-pc-lst {
    position: static;
  }

  #onetrust-pc-sdk.ot-shw-fltr #ot-fltr-modal {
    top: 0;
    width: 100%;
    height: 100%;
    max-height: none;
  }

  #onetrust-pc-sdk.ot-shw-fltr #ot-fltr-modal > div {
    margin: 0;
    box-sizing: initial;
    height: 100%;
    max-height: none;
  }

  #onetrust-pc-sdk.ot-shw-fltr #clear-filters-handler {
    padding-right: 20px;
  }

  #onetrust-pc-sdk.ot-shw-fltr #ot-anchor {
    display: none !important;
  }

  #onetrust-pc-sdk .ot-pc-footer button {
    margin: 10px;
  }
}

@media (max-width: 425px),
(max-width: 896px) and (max-height: 425px) and (orientation: landscape) {
  #onetrust-pc-sdk .ot-pc-header {
    padding-right: 20px;
  }

  #onetrust-pc-sdk .ot-pc-logo {
    margin-left: 0px;
    margin-top: 5px;
    width: 150px;
  }

  #onetrust-pc-sdk .ot-close-icon {
    width: 44px;
    height: 44px;
    background-size: 12px;
  }

  #onetrust-pc-sdk .ot-grp-hdr1 {
    float: right;
    margin-left: 10px;
    width: auto;
  }

  #onetrust-pc-sdk .ot-grp-hdr1 {
    margin-left: 0px;
    padding-right: 10px;
  }

  #onetrust-pc-sdk #ot-pvcy-hdr,
  #onetrust-pc-sdk .ot-grp-hdr1 .ot-cat-header {
    display: none;
  }

  #onetrust-pc-sdk .ot-grp-hdr1 + .ot-vlst-cntr {
    padding-top: 10px;
  }
}

@media only screen and (max-height: 610px) {
  #onetrust-pc-sdk {
    max-height: 100%;
  }
}

#onetrust-consent-sdk #onetrust-pc-sdk,
#onetrust-consent-sdk #ot-search-cntr,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-switch.ot-toggle,
#onetrust-consent-sdk #onetrust-pc-sdk ot-grp-hdr1 .checkbox,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-title:after,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-sel-blk,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-fltr-cnt,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-anchor {
  background-color: #ffffff;
}

#onetrust-consent-sdk #onetrust-pc-sdk h3,
#onetrust-consent-sdk #onetrust-pc-sdk h4,
#onetrust-consent-sdk #onetrust-pc-sdk h5,
#onetrust-consent-sdk #onetrust-pc-sdk h6,
#onetrust-consent-sdk #onetrust-pc-sdk p,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-ven-lst .ot-ven-opts p,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-desc,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-title,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-li-title,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-sel-all-hdr span,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-host-lst .ot-host-info,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-fltr-modal #modal-header,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-checkbox label span,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-lst #ot-sel-blk p,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-lst #ot-lst-title span,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-lst .back-btn-handler p,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-lst .ot-ven-name,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-pc-lst #ot-ven-lst .consent-category,
#onetrust-consent-sdk
#onetrust-pc-sdk
.ot-leg-btn-container
.ot-inactive-leg-btn,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-label-status,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-chkbox label span,
#onetrust-consent-sdk #onetrust-pc-sdk #clear-filters-handler {
  color: #696969;
}

#onetrust-consent-sdk #onetrust-pc-sdk .privacy-notice-link,
#onetrust-consent-sdk #onetrust-pc-sdk .category-vendors-list-handler,
#onetrust-consent-sdk #onetrust-pc-sdk .category-vendors-list-handler + a,
#onetrust-consent-sdk #onetrust-pc-sdk .category-host-list-handler,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-ven-link,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-host-lst .ot-host-name a,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-host-lst .ot-acc-hdr .ot-host-expand,
#onetrust-consent-sdk #onetrust-pc-sdk #ot-host-lst .ot-host-info a {
  color: #3860be;
}

#onetrust-consent-sdk #onetrust-pc-sdk .category-vendors-list-handler:hover {
  opacity: 0.7;
}

#onetrust-consent-sdk #onetrust-pc-sdk #ot-host-lst .ot-host-info,
#onetrust-consent-sdk #onetrust-pc-sdk .ot-acc-txt .ot-ven-dets {
  background-color: #f8f8f8;
}

#onetrust-consent-sdk
#onetrust-pc-sdk
button:not(#clear-filters-handler):not(.ot-close-icon):not(#filter-btn-handler):not(.ot-remove-objection-handler):not(.ot-obj-leg-btn-handler):not([aria-expanded]):not(.ot-link-btn),
#onetrust-consent-sdk
#onetrust-pc-sdk
.ot-leg-btn-container
.ot-active-leg-btn {
  background-color: #6cc04a;
  border-color: #6cc04a;
  color: #ffffff;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-active-menu {
  border-color: #6cc04a;
}

#onetrust-consent-sdk
#onetrust-pc-sdk
.ot-leg-btn-container
.ot-remove-objection-handler {
  background-color: transparent;
  border: 1px solid transparent;
}

#onetrust-consent-sdk
#onetrust-pc-sdk
.ot-leg-btn-container
.ot-inactive-leg-btn {
  background-color: #ffffff;
  color: #78808e;
  border-color: #78808e;
}

#onetrust-consent-sdk #onetrust-pc-sdk .category-menu-switch-handler {
  background-color: #f4f4f4;
}

#onetrust-consent-sdk #onetrust-pc-sdk .ot-active-menu {
  background-color: #ffffff;
}

.ot-sdk-cookie-policy {
  font-family: inherit;
  font-size: 16px;
}

.ot-sdk-cookie-policy.otRelFont {
  font-size: 1rem;
}

.ot-sdk-cookie-policy h3,
.ot-sdk-cookie-policy h4,
.ot-sdk-cookie-policy h6,
.ot-sdk-cookie-policy p,
.ot-sdk-cookie-policy li,
.ot-sdk-cookie-policy a,
.ot-sdk-cookie-policy th,
.ot-sdk-cookie-policy #cookie-policy-description,
.ot-sdk-cookie-policy .ot-sdk-cookie-policy-group,
.ot-sdk-cookie-policy #cookie-policy-title {
  color: dimgray;
}

.ot-sdk-cookie-policy #cookie-policy-description {
  margin-bottom: 1em;
}

.ot-sdk-cookie-policy h4 {
  font-size: 1.2em;
}

.ot-sdk-cookie-policy h6 {
  font-size: 1em;
  margin-top: 2em;
}

.ot-sdk-cookie-policy th {
  min-width: 75px;
}

.ot-sdk-cookie-policy a,
.ot-sdk-cookie-policy a:hover {
  background: #fff;
}

.ot-sdk-cookie-policy thead {
  background-color: #f6f6f4;
  font-weight: bold;
}

.ot-sdk-cookie-policy .ot-mobile-border {
  display: none;
}

.ot-sdk-cookie-policy section {
  margin-bottom: 2em;
}

.ot-sdk-cookie-policy table {
  border-collapse: inherit;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy {
  font-family: inherit;
  font-size: 1rem;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy h3,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy h4,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy h6,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy p,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy li,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy a,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy th,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy #cookie-policy-description,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-sdk-cookie-policy-group,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy #cookie-policy-title {
  color: dimgray;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy #cookie-policy-description {
  margin-bottom: 1em;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-sdk-subgroup {
  margin-left: 1.5em;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy #cookie-policy-description,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-sdk-cookie-policy-group-desc,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-table-header,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy a,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy span,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy td {
  font-size: 0.9em;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy td span,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy td a {
  font-size: inherit;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-sdk-cookie-policy-group {
  font-size: 1em;
  margin-bottom: 0.6em;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-sdk-cookie-policy-title {
  margin-bottom: 1.2em;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy > section {
  margin-bottom: 1em;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy th {
  min-width: 75px;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy a,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy a:hover {
  background: #fff;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy thead {
  background-color: #f6f6f4;
  font-weight: bold;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-mobile-border {
  display: none;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy section {
  margin-bottom: 2em;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-sdk-subgroup ul li {
  list-style: disc;
  margin-left: 1.5em;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-sdk-subgroup ul li h4 {
  display: inline-block;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table {
  border-collapse: inherit;
  margin: auto;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  border-spacing: initial;
  width: 100%;
  overflow: hidden;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table th,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table td {
  border-bottom: 1px solid #d7d7d7;
  border-right: 1px solid #d7d7d7;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table tr:last-child td {
  border-bottom: 0px;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table tr th:last-child,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table tr td:last-child {
  border-right: 0px;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table .ot-host,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table .ot-cookies-type {
  width: 25%;
}

.ot-sdk-cookie-policy[dir="rtl"] {
  text-align: left;
}

#ot-sdk-cookie-policy h3 {
  font-size: 1.5em;
}

@media only screen and (max-width: 530px) {
  .ot-sdk-cookie-policy:not(#ot-sdk-cookie-policy-v2) table,
  .ot-sdk-cookie-policy:not(#ot-sdk-cookie-policy-v2) thead,
  .ot-sdk-cookie-policy:not(#ot-sdk-cookie-policy-v2) tbody,
  .ot-sdk-cookie-policy:not(#ot-sdk-cookie-policy-v2) th,
  .ot-sdk-cookie-policy:not(#ot-sdk-cookie-policy-v2) td,
  .ot-sdk-cookie-policy:not(#ot-sdk-cookie-policy-v2) tr {
    display: block;
  }

  .ot-sdk-cookie-policy:not(#ot-sdk-cookie-policy-v2) thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .ot-sdk-cookie-policy:not(#ot-sdk-cookie-policy-v2) tr {
    margin: 0 0 1em 0;
  }

  .ot-sdk-cookie-policy:not(#ot-sdk-cookie-policy-v2) tr:nth-child(odd),
  .ot-sdk-cookie-policy:not(#ot-sdk-cookie-policy-v2) tr:nth-child(odd) a {
    background: #f6f6f4;
  }

  .ot-sdk-cookie-policy:not(#ot-sdk-cookie-policy-v2) td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }

  .ot-sdk-cookie-policy:not(#ot-sdk-cookie-policy-v2) td:before {
    position: absolute;
    height: 100%;
    left: 6px;
    width: 40%;
    padding-right: 10px;
  }

  .ot-sdk-cookie-policy:not(#ot-sdk-cookie-policy-v2) .ot-mobile-border {
    display: inline-block;
    background-color: #e4e4e4;
    position: absolute;
    height: 100%;
    top: 0;
    left: 45%;
    width: 2px;
  }

  .ot-sdk-cookie-policy:not(#ot-sdk-cookie-policy-v2) td:before {
    content: attr(data-label);
    font-weight: bold;
  }

  .ot-sdk-cookie-policy:not(#ot-sdk-cookie-policy-v2) li {
    word-break: break-word;
    word-wrap: break-word;
  }

  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table {
    overflow: hidden;
  }

  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table td {
    border: none;
    border-bottom: 1px solid #d7d7d7;
  }

  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table,
  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy thead,
  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy tbody,
  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy th,
  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy td,
  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy tr {
    display: block;
  }

  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table .ot-host,
  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table .ot-cookies-type {
    width: auto;
  }

  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy tr {
    margin: 0 0 1em 0;
  }

  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy td:before {
    height: 100%;
    width: 40%;
    padding-right: 10px;
  }

  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy td:before {
    content: attr(data-label);
    font-weight: bold;
  }

  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy li {
    word-break: break-word;
    word-wrap: break-word;
  }

  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: -9999;
  }

  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table tr:last-child td {
    border-bottom: 1px solid #d7d7d7;
    border-right: 0px;
  }

  #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy
  table
  tr:last-child
  td:last-child {
    border-bottom: 0px;
  }
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy h5,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy h6,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy li,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy p,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy a,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy span,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy td,
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy #cookie-policy-description {
  color: #696969;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy th {
  color: #696969;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy .ot-sdk-cookie-policy-group {
  color: #696969;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy #cookie-policy-title {
  color: #696969;
}

#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table th {
  background-color: #f8f8f8;
}
</style>