import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Home from '../views/Home.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/production/:id/:variation?',
        name: 'production',
        component: () => import("../views/production.vue"),
    }, {
        path: '/answers',
        name: 'answers',
        component: () => import("../views/answers.vue"),
    },
    {
        path: '/games/budesonid',
        name: 'budesonid',
        component: () => import("../views/games/Budesonid.vue"),
    },
    {
        path: '/games/clopidogrel',
        name: 'clopidogrel',
        component: () => import("../views/games/Clopidogrel.vue"),
    },
    {
        path: '/games/acetylcysteine',
        name: 'acetylcysteine',
        component: () => import("../views/games/Acetylcysteine.vue"),
    },
    {
        path: '/games/atorvastatin',
        name: 'atorvastatin',
        component: () => import("../views/games/Atorvastatin.vue"),
    },
    {
        path: '/games/desloratadine',
        name: 'desloratadine',
        component: () => import("../views/games/Desloratadine.vue"),
    },
    {
        path: '/games/omeprazol',
        name: 'omeprazol',
        component: () => import("../views/games/Omeprazol.vue"),
    },
    {
        path: '/games/ramipril',
        name: 'ramipril',
        component: () => import("../views/games/Ramipril.vue"),
    },
    {
        path: '/games',
        name: 'games',
        component: () => import("../views/games.vue"),
    },
    {
        path: '/games/stoptusin',
        name: 'stoptusin',
        component: () => import("../views/games/Stoptusin.vue"),
    },
    {
        path: '/games/asa',
        name: 'askTeva',
        component: () => import("../views/games/ask-teva.vue"),
    },
    {
        path: '/games/valsartan',
        name: 'valsartan',
        component: () => import("../views/games/Valsartan.vue"),
    },
    {
        path: '/brochures',
        name: 'brochures',
        component: () => import("../views/brochures.vue"),
    },
    {
        path: '/brochures/medicines-are-available',
        name: 'brochuresItem1',
        component: () => import("../views/brochures/brochuresItem1.vue"),
    },
    {
        path: '/brochures/estsitalopram',
        name: 'brochuresItem2',
        component: () => import("../views/brochures/brochuresItem2.vue"),
    },
    {
        path: '/brochures/lercanidipin',
        name: 'brochuresItem3',
        component: () => import("../views/brochures/brochuresItem3.vue"),
    },
    {
        path: '/brochures/pantoprazol',
        name: 'brochuresItem4',
        component: () => import("../views/brochures/brochuresItem4.vue"),
    }, {
        path: '/useful-by-teva',
        name: 'usefulByTeva',
        component: () => import("../views/articles-by-teva.vue"),
    }, {
        path: '/page/:id',
        name: 'page',
        component: () => import("../views/page.vue"),
    },
    {
        path: '/brochures/pregabalin',
        name: 'brochuresItem5',
        component: () => import("../views/brochures/brochuresItem5.vue"),
    },
    {
        path: '/brochures/budesonid',
        name: 'brochuresItem6',
        component: () => import("../views/brochures/brochuresItem6.vue"),
    },
    {
        path: '/brochures/ramipril',
        name: 'brochuresItem7',
        component: () => import("../views/brochures/brochuresItem7.vue"),
    },
    {
        path: '/brochures/rozuvastatin',
        name: 'brochuresItem8',
        component: () => import("../views/brochures/brochuresItem8.vue"),
    },
    {
        path: '/brochures/valsartan-h',
        name: 'brochuresItem9',
        component: () => import("../views/brochures/brochuresItem9.vue"),
    },
    {
        path: '/brochures/valsartan',
        name: 'brochuresItem10',
        component: () => import("../views/brochures/brochuresItem10.vue"),
    },
    {
        path: '/brochures/AH_treatment_scheme',
        name: 'brochuresItem11',
        component: () => import("../views/brochures/brochuresItem11.vue"),
    },
    {
        path: '/amazing_medicine_game',
        name: 'quizHub',
        component: () => import("../views/quiz-game/hub.vue"),
    },
    {
        path: '/amazing_medicine_game/introduction',
        name: 'stage1',
        component: () => import("../views/quiz-game/stage1.vue"),
    }, {
        path: '/amazing_medicine_game/rules',
        name: 'stage2',
        component: () => import("../views/quiz-game/stage2.vue"),

    },
    {
        path: '/amazing_medicine_game/:id',
        name: 'quizGame',
        component: () => import("../views/quiz-game/quiz-game.vue"),
    },
    {
        path: '/cardio-calc',
        name: 'calc',
        component: () => import("../views/calc.vue"),
    },
    {
        path: '*',
        redirect: "/"
    },
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,

    scrollBehavior: function (to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
                offset: {x: 0, y: 85},
            }
        } else {
            return {x: 0, y: 0}
        }
    },
})

export default router