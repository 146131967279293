import "@babel/polyfill";

import Vue from 'vue'
import App from './App.vue'
import {sync} from 'vuex-router-sync'
import router from './router'
import store from './store'


Vue.config.productionTip = false

import VueAwesomeSwiper from 'vue-awesome-swiper'

import VueTheMask from 'vue-the-mask'

Vue.use(VueTheMask)

// import style
import 'swiper/css/swiper.css'

const unsync = sync(store, router) // done. Returns an unsync callback fn

Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
